import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// r3f
import { useFrame, useLoader, ThreeEvent } from '@react-three/fiber';
import { Html, Outlines, DragControls } from '@react-three/drei';
import * as THREE from 'three';

// config
import { colors } from 'theming/colors';

// mui
import { Stack } from '@mui/system';
import { Box, CircularProgress, Portal } from '@mui/material';

// ui
import ToolButtons from './PlateTools';

// services
import { PIMService } from 'services/PIMService';

// store
import { AppDispatch } from 'store';
import { setUnsavedChanges, updatePlateById } from './moodboardState';
import { selectDesignCodeProject } from 'features/projects/state/projectState';

// types
import { PlateInterface, PlateType, TextureData, ToolType } from './types';

const Plate: React.FC<PlateInterface> = ({
    plateData,
    focused,
    onPress, // sets focused plate
    onDelete,
    platePanelContainer,
    index,
    movePlateUp,
    movePlateDown,
}) => {
    /**
     *  refs
     */

    const meshRef = useRef<THREE.Mesh>(null);
    const groupRef = useRef<THREE.Group>(null);
    const boxRef = useRef<THREE.BoxGeometry>(null);
    const prevDelta = useRef<THREE.Vector3>(new THREE.Vector3(0, 0, 0));

    /**
     *  local state
     */

    const [selectedTool, setSelectedTool] = useState<ToolType>('translate');
    const [targetRotation, setTargetRotation] = useState(
        (plateData.rotation * Math.PI) / 2
    );
    const [locked, setLocked] = useState(plateData.locked);
    const [height, setHeight] = useState(plateData.height);
    const [currentImgIndex, setCurrentImgIndex] = useState(0);
    const [textureURL, setTextureURL] = useState('/placeholder_plate.png');
    const [loadingTexture, setLoadingTexture] = useState(false);
    const [geometryReady, setGeometryReady] = useState(false);
    const [geometryDimensions, setGeometryDimensions] = useState([1, 1, 0.1]);
    const [initialSpawning, setInitialSpawning] = useState(true);
    const [dragStartPos, setDragStartPos] = useState<THREE.Vector3>(
        new THREE.Vector3()
    );
    const [plateScale, setPlateScale] = useState<number>(
        plateData.transformScale[0]
    );

    /**
     *  properties
     */

    const dispatch: AppDispatch = useDispatch();
    const i18n = useTranslation();
    const designCodeProject = useSelector(selectDesignCodeProject);
    const texture = useLoader(THREE.TextureLoader, textureURL);

    const initialPosition = plateData.position;
    const transformScale = plateData.transformScale;
    const plateThickness = 1;

    const pimService = new PIMService();

    /**
     *  features
     */

    const handleObjectChange = useCallback(
        (data: {
            newLock?: boolean;
            newRotationTick?: number;
            newHeight?: number;
            newIndex?: number;
            cropData?: {
                scale: { x: number; y: number };
                offset: number[];
            };
            transformScale?: [number, number, number];
        }) => {
            if (!groupRef.current) return;

            let updatedTextures: TextureData[] = [];
            const posX = groupRef.current.position.x;
            const posY = groupRef.current.position.y;
            const posZ = groupRef.current.position.z;
            const scaleX = data.transformScale
                ? data.transformScale[0]
                : groupRef.current?.scale.x;
            const scaleY = data.transformScale
                ? data.transformScale[1]
                : groupRef.current?.scale.y;
            const scaleZ = data.transformScale
                ? data.transformScale[2]
                : plateThickness; // constant thickness
            const newHeight = data.newHeight || plateData.height;
            const newRotationTick = data.newRotationTick ?? plateData.rotation;
            const newLock = data.newLock || plateData.locked;

            const id = plateData.id;

            if (data.newIndex !== undefined) {
                updatedTextures = plateData.textures.map((texture, index) => ({
                    ...texture,
                    isSelected: index === data.newIndex,
                }));
            }

            const updatedPlate = {
                ...plateData,
                position: [posX, posY, posZ],
                transformScale: [scaleX, scaleY, scaleZ],
                height: newHeight, // layering height
                rotation: newRotationTick,
                locked: newLock,
                ...(updatedTextures.length && { textures: updatedTextures }),
                ...(data.cropData && { cropData: data.cropData }),
            };

            dispatch(updatePlateById({ id, updatedPlate }));
            dispatch(setUnsavedChanges(true));
        },
        [plateData, dispatch]
    );

    const handleNextImage = useCallback(() => {
        setCurrentImgIndex((prevIndex) => {
            const newIndex =
                prevIndex === plateData.textures.length - 1 ? 0 : prevIndex + 1;
            handleObjectChange({ newIndex });
            return newIndex;
        });
    }, [plateData.textures.length, handleObjectChange]);

    const handlePreviousImage = useCallback(() => {
        setCurrentImgIndex((prevIndex) => {
            const newIndex =
                prevIndex === 0 ? plateData.textures.length - 1 : prevIndex - 1;
            handleObjectChange({ newIndex });
            return newIndex;
        });
    }, [plateData.textures.length, handleObjectChange]);

    const handlePlateClick = (event?: ThreeEvent<MouseEvent>) => {
        if (designCodeProject) return;

        // stop event propagation to prevent clicking through to lower plates
        event?.stopPropagation();

        if (selectedTool === 'inactive') setSelectedTool('translate');
        onPress?.();
        if (locked) setSelectedTool('inactive');
    };

    // resetTransformations() removed

    const lock = () => {
        setLocked(!locked);
        !locked ? setSelectedTool('inactive') : setSelectedTool('translate');
        handleObjectChange({ newLock: !locked });
    };

    const deletePlate = () => {
        onDelete?.();
    };

    const updateTexture = async (options?: { reset?: boolean }) => {
        if (!plateData?.textures?.length) {
            console.error('No materials found - image load error?');
            onDelete?.();
            return;
        }

        const selectedTexture =
            plateData.textures.find((texture, index) => {
                if (texture?.isSelected) {
                    setCurrentImgIndex?.(index);
                    return true;
                }
                return false;
            }) || plateData.textures[0];

        try {
            // geometry needs to be ready for <Outlines /> component
            if (options?.reset) {
                setGeometryReady(false);
            } else {
                setLoadingTexture(true);
            }

            let fetchedTexture: Blob | null = null;
            const imageUrl = selectedTexture?.src;
            if (designCodeProject) {
                const designCodeParameter = `?designcode=${designCodeProject?.designCode}`;
                fetchedTexture = await pimService.getImage(
                    imageUrl + designCodeParameter
                );
            } else {
                fetchedTexture = await pimService.getImage(imageUrl);
            }

            if (fetchedTexture !== null) {
                const objectURL = URL.createObjectURL(fetchedTexture);
                setTextureURL(objectURL);

                // update geometry dimensions based on the new texture
                const img = new Image();
                img.src = objectURL;
                img.onload = () => {
                    const aspectRatio = img.width / img.height;
                    const width = 1;
                    const height = 1 / aspectRatio;
                    setGeometryDimensions([width, height, 0.01]);
                };

                // reload texture via three.js texture loader to ensure proper wrapping and update settings
                const textureLoader = new THREE.TextureLoader();
                textureLoader.load(
                    objectURL,
                    (loadedTexture) => {
                        loadedTexture.wrapS = loadedTexture.wrapT =
                            THREE.ClampToEdgeWrapping;
                        loadedTexture.repeat.set(1, 1);
                        loadedTexture.needsUpdate = true;
                        if (options?.reset) {
                            // wait for next frame to ensure geometry is updated
                            requestAnimationFrame(() => {
                                setGeometryReady(true);
                            });
                        }
                    },
                    undefined,
                    (error) => {
                        console.error('error loading texture:', error);
                    }
                );
            } else {
                console.error('No materials found - image load error?');
                onDelete?.();
            }
        } catch (error) {
            console.error('error in updateTexture:', error);
        } finally {
            if (!options?.reset) {
                setLoadingTexture(false);
            }
        }
    };

    const handleRotateClick = () => {
        if (groupRef.current) {
            const newRotation = (plateData.rotation + 1) * (Math.PI / 2);
            setTargetRotation(newRotation);
            handleObjectChange({ newRotationTick: plateData.rotation + 1 });
        }
    };

    const openPlate = async () => {
        const plateInfo: PlateType = plateData;

        if (plateInfo?.pimProductId) {
            const product = await pimService.getProductById(
                plateInfo.pimProductId,
                0
            );

            if (product) {
                // const url = product.overviewUrl; // broken atm 07.01.2025
                // need to replace newUrl with product.overviewUrl as soon as it's fixed
                const newUrl = `https://datasheet.floorin.ee/?collection=flProducts&itemId=${product.id}&hideImg=true&locale=et`;
                window.open(newUrl, '_blank', 'noreferrer');
            }
        }
    };

    const handleMoveDown = () => {
        // force translate tool when starting to change layer order
        setSelectedTool('translate');
        movePlateDown();
    };

    const handleMoveUp = () => {
        setSelectedTool('translate');
        movePlateUp();
    };

    const handleToolClick = (tool: ToolType) => {
        switch (tool) {
            case 'translate':
                setSelectedTool('translate');
                break;
            case 'rotate':
                handleRotateClick();
                break;
            case 'moveDown':
                handleMoveDown();
                break;
            case 'moveUp':
                handleMoveUp();
                break;
            case 'scale':
                setSelectedTool('scale');
                break;
            case 'crop':
                setSelectedTool('crop');
                break;
            case 'lock':
                lock();
                break;
            case 'delete':
                deletePlate();
                break;
            case 'info':
                openPlate();
                break;
            case 'previous':
                handlePreviousImage();
                break;
            case 'next':
                handleNextImage();
                break;
        }
    };

    const handleDragStart = (pos: THREE.Vector3) => {
        // reset previous delta so that drag delta is measured from here
        prevDelta.current.set(0, 0, 0);

        //Set pointer start position
        setDragStartPos(pos);
    };

    const handleDragging = (
        localMatrix: THREE.Matrix4,
        deltaLocalMatrix: THREE.Matrix4,
        worldMatrix: THREE.Matrix4,
        deltaWorldMatrix: THREE.Matrix4
    ) => {
        if (focused && groupRef.current && !locked) {
            const localPos = new THREE.Vector3();
            localMatrix.decompose(
                localPos,
                new THREE.Quaternion(),
                new THREE.Vector3()
            );
            if (selectedTool === 'translate') {
                handleDragTransform(deltaWorldMatrix);
            } else if (selectedTool === 'scale') {
                handleDragScale(localPos);
            }
        }
    };

    const handleDragEnd = () => {
        if (focused) {
            handleObjectChange({});
        }
        // reset previous delta for subsequent drags
        // it's being reset in 2 places: defensive programming concept
        prevDelta.current.set(0, 0, 0);
    };

    const handleDragTransform = (deltaWorldMatrix: THREE.Matrix4) => {
        const quaternion = new THREE.Quaternion();
        const scale = new THREE.Vector3();

        // decompose the cumulative delta matrix into a vector
        const currentDelta = new THREE.Vector3();
        deltaWorldMatrix?.decompose(currentDelta, quaternion, scale);

        // calculate incremental change by subtracting the previous cumulative delta
        const incrementalDelta = currentDelta.clone().sub(prevDelta.current);

        // update the stored delta to the current cumulative delta
        prevDelta.current.copy(currentDelta);

        // apply the incremental change to current position
        // only update x and z, and keep y set from plateData
        groupRef.current!.position.x += incrementalDelta.x;
        groupRef.current!.position.z += incrementalDelta.z;
        groupRef.current!.position.y = plateData?.height || height;
    };

    /**
     * Sets the scale of the plate mesh while enforcing minimum and maximum scale constraints
     * @param {number} newScale - The new scale value to apply to the plate
     * @returns {void}
     */
    const handleSettingPlateScale = (newScale: number): void => {
        //Set min/max scale values
        const minScale = 0.5;
        const maxScale = 10;
        let scaleValue = newScale;

        //Override new scale if it exceeds min/max scale values
        if (newScale > maxScale) {
            scaleValue = maxScale;
        } else if (newScale < minScale) {
            scaleValue = minScale;
        }

        //Scale plate
        groupRef.current!.scale.set(scaleValue, scaleValue, plateThickness);
        setPlateScale(scaleValue);
    };

    /**
     * Handles scaling of the plate during drag operations based on pointer movement
     * @param {THREE.Vector3} position - The current position vector of the drag operation
     * @returns {void}
     *
     * @description
     * This function:
     * 1. Calculates scale changes based on Z-axis movement
     * 2. Applies new scale through handleSettingPlateScale
     * The scale change is proportional to the distance moved from the drag start position on the Z-axis
     */
    const handleDragScale = (position: THREE.Vector3): void => {
        //Assign initial scale for cases where new Z coordinate is the same as the starting one
        let newScale = plateScale;
        //Adjust the sensitivity of scale tool
        const scaleRatio = 2;

        //Absolute Z coordinate change
        const absoluteDz = Math.abs(position.z - dragStartPos.z);
        const scaleWithRatio = absoluteDz * scaleRatio;
        //current Z and start Z diff
        const dz = position.z - dragStartPos.z;

        //Assign scale value based on pointer Z-axis movement
        if (dz > 0) {
            newScale = plateData.transformScale[0] + scaleWithRatio;
        } else if (dz < 0) {
            newScale = plateData.transformScale[0] - scaleWithRatio;
        }

        //Set plate scale
        handleSettingPlateScale(newScale);
    };

    const loadTexture = async () => {
        setGeometryReady(false); // reset

        await updateTexture({ reset: false });

        // wait for next frame to ensure geometry is updated
        requestAnimationFrame(() => {
            setGeometryReady(true);
        });
    };

    /**
     *  side effects
     */

    // fetch texture and geometry state on img change
    useEffect(() => {
        loadTexture();

        // cleanup
        return () => {
            if (textureURL.startsWith('blob:')) {
                URL.revokeObjectURL(textureURL);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentImgIndex]);

    // initialize plate rotation
    useEffect(() => {
        const newRotation = plateData.rotation * (-Math.PI / 2);

        if (groupRef.current) {
            groupRef.current.rotation.x = Math.PI / 2;
            groupRef.current.rotation.y = 0;
            groupRef.current.rotation.z = newRotation;
            (groupRef.current as any).position.y = height;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // restore plate data on single plate change which is
    // triggered by updatePlates or updatePlateById actions
    useEffect(() => {
        // Restore rotation
        setTargetRotation((plateData.rotation * Math.PI) / 2);
        // Restore scale
        handleSettingPlateScale(plateData.transformScale[0]);
        // Restore texture
        updateTexture({ reset: true });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plateData]);

    // force translate tool when focused
    useEffect(() => {
        if (focused) {
            setSelectedTool('translate');
        }
    }, [focused]);

    /**
     *  ticker
     */

    useFrame(() => {
        if (initialSpawning) {
            groupRef.current?.scale.set(
                transformScale[0],
                transformScale[1],
                transformScale[2]
            );
            setInitialSpawning(false);
        }

        if (selectedTool === 'scale' && groupRef.current) {
            const scale = groupRef.current.scale;
            const uniformScale = scale.x;
            // preserve z scale for layering (movePlateUp/Down) to work
            groupRef.current.scale.set(
                uniformScale,
                uniformScale,
                plateThickness
            );
        }
    });

    useFrame(() => {
        if (groupRef.current) {
            groupRef.current.rotation.x = Math.PI / 2;
            groupRef.current.rotation.y = 0;
            groupRef.current.rotation.z = THREE.MathUtils.lerp(
                groupRef.current.rotation.z,
                targetRotation,
                0.1
            );
        }
    });

    return (
        <>
            <DragControls
                autoTransform={false}
                // axisLock="y"
                onDragStart={handleDragStart}
                onDrag={handleDragging}
                onDragEnd={handleDragEnd}
            >
                <group
                    ref={groupRef}
                    position={[
                        initialPosition[0],
                        initialPosition[1],
                        initialPosition[2],
                    ]}
                    castShadow
                    receiveShadow
                    renderOrder={plateData.height * 1000}
                    onClick={(e) => {
                        e.stopPropagation();
                        handlePlateClick(e);
                    }}
                >
                    <mesh
                        ref={meshRef}
                        rotation={[0, 0, Math.PI]}
                        receiveShadow
                        castShadow
                    >
                        {!loadingTexture && (
                            <boxGeometry
                                args={[
                                    geometryDimensions[0],
                                    geometryDimensions[1],
                                    geometryDimensions[2],
                                ]}
                                ref={boxRef}
                            />
                        )}
                        <meshStandardMaterial map={texture} />
                        {focused && boxRef.current && geometryReady && (
                            <Outlines
                                thickness={0.02}
                                color={colors.orange}
                                screenspace
                            />
                        )}
                    </mesh>

                    {loadingTexture && (
                        <Html zIndexRange={[1, 2]} center>
                            <CircularProgress />
                        </Html>
                    )}
                </group>
            </DragControls>
            <Html>
                <Portal container={() => platePanelContainer?.current}>
                    {!loadingTexture && focused && !designCodeProject && (
                        <Box id={'plateBox'} component="div">
                            <Stack
                                direction="column"
                                spacing={1}
                                justifyContent="center"
                                alignItems="center"
                                bgcolor={'white'}
                                padding={2}
                                borderRadius={3}
                                sx={{
                                    boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.1)',
                                }}
                            >
                                <Stack direction="row" spacing={1}>
                                    <ToolButtons
                                        selectedTool={selectedTool}
                                        locked={locked}
                                        currentImageIndex={currentImgIndex + 1}
                                        totalImages={plateData.textures.length}
                                        onToolClick={handleToolClick}
                                    />
                                </Stack>
                                {/* <Slider /> element  removed, see GH ref: pull/50 */}
                            </Stack>
                        </Box>
                    )}
                </Portal>
            </Html>
        </>
    );
};

export default Plate;
