import React, {
    forwardRef,
    useState,
    useImperativeHandle,
    useEffect,
    useRef,
} from 'react';
import { Grid, OrbitControls, Outlines, Plane } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    generateCurrentTimestampString,
    generateHashFromTimestamp,
} from 'utils';
import DirectionalLight from './DirectionalLight';
import {
    selectVariationSelected,
    setUnsavedChanges,
    updatePlates,
} from './moodboardState';
import Plate from './Plate';
import { PlateType } from './types';
import { MediaService } from 'services/MediaService';
import { updateVariation } from 'services/projectServices';
import {
    selectDesignCodeProject,
    selectDesignCodeRoomById,
    selectRoomById,
    selectSelectedProject,
    setProjectLoadingState,
} from 'features/projects/state/projectState';
import { AppDispatch, RootState } from 'store';

const plateThickness = 1; // constant thickness for all plates

const TopDownScene = forwardRef<
    {
        takeScreenshot: () => void;
        uploadScreenshot: () => Promise<Variation | null>;
        resetView: () => Promise<void>;
    },
    {
        gridActive: boolean;
        setGridActive: (gridActive: boolean) => void;
        plates: PlateType[];
        deletePlate: (index: string) => void;
        platePanelContainer: React.RefObject<HTMLDivElement>;
        layersCamera: boolean;
    }
>(
    (
        {
            gridActive = true,
            setGridActive,
            plates,
            deletePlate,
            platePanelContainer,
            layersCamera,
        },
        ref
    ) => {
        const [focusedPlate, setFocusedPlate] = useState<
            number | string | null
        >(null);
        const dispatch: AppDispatch = useDispatch();
        const [userTookScreenshot, setUserTookScreenshot] =
            useState<boolean>(false);
        const params = useParams();

        const orbitControlsRef = useRef<any>(null);
        const [borderSize, setBorderSize] = useState({
            width: 4.08,
            height: 3.72,
        });

        const designCodeProject = useSelector(selectDesignCodeProject);
        const selectedProjectFromRegularSession = useSelector(
            selectSelectedProject
        );

        // Selected project
        const selectedProject = !designCodeProject
            ? selectedProjectFromRegularSession
            : designCodeProject;

        const selectedRoomFromRegularSession = useSelector((state: RootState) =>
            selectRoomById(state, params?.roomId)
        );
        const selectedRoomFromDesignCodeProject = useSelector(
            (state: RootState) =>
                selectDesignCodeRoomById(state, params?.roomId)
        );

        // Selected room
        const selectedRoom = designCodeProject
            ? selectedRoomFromDesignCodeProject
            : selectedRoomFromRegularSession;

        const handleFocus = (index: number | string | null) => {
            setFocusedPlate(index);
        };

        const selectedVariation: Variation | null = useSelector(
            selectVariationSelected
        );

        const takeScreenshot = async () => {
            setFocusedPlate(null);

            await new Promise((resolve) => {
                setTimeout(resolve, 50);
            });

            const projectName = selectedProject?.name;
            const roomName = selectedRoom?.name;
            const variationName = selectedVariation?.name;
            const currentDate = generateCurrentTimestampString();

            // Take the screenshot
            gl.render(scene, camera);
            const screenshot = gl.domElement.toDataURL('image/png');

            // Create a link element, set its href to the cropped screenshot data URL, and trigger a download
            const link = document.createElement('a');
            link.href = screenshot;
            link.download =
                projectName +
                '_' +
                roomName +
                '_' +
                variationName +
                '_' +
                currentDate +
                '.png';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };

        const uploadScreenshot = async (): Promise<Variation | null> => {
            const cropWidth = 1400;
            const cropHeight = 1200;
            setFocusedPlate(null);

            if (gridActive) {
                setGridActive(false);
            }

            await new Promise((resolve) => setTimeout(resolve, 100));

            // Take the screenshot
            gl?.render(scene, camera);
            const screenshot = gl?.domElement.toDataURL('image/jpeg', 0.9);

            if (!screenshot || !selectedVariation) {
                return null;
            }

            // create and load image synchronously
            const img = new Image();
            await new Promise((resolve) => {
                img.onload = resolve;
                img.src = screenshot;
            });

            const mediaService = new MediaService();

            // create a canvas to crop the image
            const canvas = document.createElement('canvas');

            // get original dimensions
            const originalWidth = img.width;
            const originalHeight = img.height;

            // calculate aspect ratios
            const originalAspectRatio = originalWidth / originalHeight;
            const cropAspectRatio = cropWidth / cropHeight;

            let drawWidth, drawHeight, offsetX, offsetY;

            if (originalAspectRatio > cropAspectRatio) {
                drawHeight = cropHeight;
                drawWidth = drawHeight * originalAspectRatio;
                offsetX = (drawWidth - cropWidth) / 2;
                offsetY = 0;
            } else {
                drawWidth = cropWidth;
                drawHeight = drawWidth / originalAspectRatio;
                offsetX = 0;
                offsetY = (drawHeight - cropHeight) / 2;
            }

            canvas.width = cropWidth;
            canvas.height = cropHeight;

            const ctx = canvas.getContext('2d');
            ctx?.drawImage(img, -offsetX, -offsetY, drawWidth, drawHeight);

            const currentDate = generateCurrentTimestampString();

            // convert the cropped canvas to a data URL
            const croppedScreenshot = canvas.toDataURL('image/jpeg', 0.9);
            const blob = await (await fetch(croppedScreenshot))?.blob();
            const file = new File(
                [blob],
                'moodboard_screenshot_' +
                    generateHashFromTimestamp() +
                    '_' +
                    currentDate +
                    '.jpg',
                { type: 'image/jpeg' }
            );

            const formData = new FormData();
            formData.append('file', file);

            dispatch(setProjectLoadingState(true));

            try {
                // upload
                const savedScreenshot: MediaDoc =
                    await mediaService.saveScreenshot(formData);

                if (savedScreenshot && selectedVariation?.id) {
                    const previousSnapshotsIds =
                        selectedVariation?.snapshots?.map(
                            (snapshot: Snapshot) => snapshot.id
                        ) || [];

                    // add snapshot to current variation
                    const updatedVariation = await updateVariation(
                        selectedVariation.id,
                        {
                            snapshots: [
                                ...previousSnapshotsIds,
                                savedScreenshot.id,
                            ],
                        }
                    );

                    return updatedVariation;
                }
            } finally {
                dispatch(setProjectLoadingState(false));
            }

            return null;
        };

        useEffect(() => {
            if (userTookScreenshot && focusedPlate === null) {
                takeScreenshot();
                setUserTookScreenshot(false);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [focusedPlate, userTookScreenshot]);

        useEffect(() => {
            // Reset the focused plate when new variation is changed.
            handleFocus(null);
        }, [selectedVariation]);

        const { scene, camera, gl } = useThree();

        useEffect(() => {
            if (gl) {
                gl.sortObjects = true;
                gl.autoClear = true;
            }
        }, [gl]);

        // 'ref' is the ref from the parent component (Moodboard.tsx)
        // here in the 'useImperativeHandle' new functions are declared
        // and forwarded to the parent
        useImperativeHandle(ref, () => ({
            takeScreenshot() {
                setUserTookScreenshot(true);
                setFocusedPlate(null);
            },

            async uploadScreenshot() {
                setFocusedPlate(null);
                // Wait for next frame to ensure state update has been processed
                await new Promise((resolve) => requestAnimationFrame(resolve));
                return uploadScreenshot();
            },

            async resetView() {
                await resetView();
            },
        }));

        const movePlateDown = (id: string) => {
            const plateInList = plates.find((p) => p.id === id);
            if (!plateInList) return;

            const plateIndex = plates.findIndex((p) => p.id === id);
            if (plateIndex <= 0) return; // Already leftmost or not found

            // Create new array with plates in swapped positions
            const newPlates = [...plates];
            [newPlates[plateIndex - 1], newPlates[plateIndex]] = [
                newPlates[plateIndex],
                newPlates[plateIndex - 1],
            ];

            // Update heights for rendering order
            const newPlatesWithHeight = newPlates.map((plate, index) => {
                const heightValue = (index + 1) * 0.1;
                return {
                    ...plate,
                    height: heightValue,
                    position: [
                        plate.position[0],
                        heightValue,
                        plate.position[2],
                    ],
                    // Preserve original scale values
                    transformScale: [
                        plate.transformScale[0],
                        plate.transformScale[1],
                        plateThickness, // constant thickness
                    ],
                };
            });

            dispatch(updatePlates(newPlatesWithHeight));
            dispatch(setUnsavedChanges(true));
        };

        const movePlateUp = (id: string) => {
            const plateInList = plates.find((p) => p.id === id);
            if (!plateInList) return;

            const plateIndex = plates.findIndex((p) => p.id === id);
            if (plateIndex >= plates.length - 1) return; // Already rightmost or not found

            // Create new array with plates in swapped positions
            const newPlates = [...plates];
            [newPlates[plateIndex], newPlates[plateIndex + 1]] = [
                newPlates[plateIndex + 1],
                newPlates[plateIndex],
            ];

            const newPlatesWithHeight = newPlates.map((plate, index) => {
                const heightValue = (index + 1) * 0.1;
                return {
                    ...plate,
                    height: heightValue,
                    position: [
                        plate.position[0],
                        heightValue,
                        plate.position[2],
                    ],
                    // Preserve original scale values
                    transformScale: [
                        plate.transformScale[0],
                        plate.transformScale[1],
                        plateThickness,
                    ],
                };
            });

            dispatch(updatePlates(newPlatesWithHeight));
            dispatch(setUnsavedChanges(true));
        };

        const resetView = async () => {
            if (orbitControlsRef.current) {
                setFocusedPlate(null);

                await new Promise((resolve) => {
                    setTimeout(resolve, 50);
                });

                // reset the orbit controls
                orbitControlsRef.current.reset();

                // reset target to origin
                orbitControlsRef.current.target.set(0, 0, 0);

                // reset camera position and rotation for top-down view
                orbitControlsRef.current.object.position.set(0, 50, 0);
                orbitControlsRef.current.object.rotation.set(
                    -Math.PI / 2,
                    0,
                    0
                );
                orbitControlsRef.current.object.zoom = 250;

                // ensure quaternion matches euler rotation
                orbitControlsRef.current.object.quaternion.setFromEuler(
                    orbitControlsRef.current.object.rotation
                );

                // update projection matrix for zoom changes
                orbitControlsRef.current.object.updateProjectionMatrix();

                // final update to ensure all changes are applied
                orbitControlsRef.current.update();
            }
        };

        return (
            <>
                <OrbitControls
                    ref={orbitControlsRef}
                    enablePan
                    enableZoom
                    enableRotate={false}
                    // very important to disable damping if switching between different camera views (top-down/perspective)
                    // as without it the camera will not update correctly and rotation bugs appear
                    enableDamping={false}
                    minZoom={100}
                    maxZoom={500}
                />

                {gridActive && (
                    <Grid
                        position={[0, 0.02, 0]}
                        args={[100, 100]}
                        cellSize={0}
                        sectionSize={0.1}
                        sectionColor="#d3d3d3"
                        cellColor="#d3d3d3"
                        infiniteGrid
                    />
                )}

                <ambientLight intensity={2.8} />
                <DirectionalLight />
                {/* <SoftShadows size={10} focus={0.7} samples={24} /> */}

                {plates.map((plate, index) => (
                    <Plate
                        index={index}
                        key={plate.id}
                        focused={focusedPlate === plate.id}
                        onPress={() => handleFocus(plate.id)}
                        plateData={plate}
                        onDelete={() => {
                            deletePlate(plate.id);
                            handleFocus(null);
                        }}
                        platePanelContainer={platePanelContainer}
                        movePlateUp={() => movePlateUp(plate.id)}
                        movePlateDown={() => movePlateDown(plate.id)}
                    />
                ))}

                {gridActive && (
                    <mesh receiveShadow name="screenshot-border">
                        <boxGeometry
                            args={[borderSize.width, 0.01, borderSize.height]}
                        />
                        <meshStandardMaterial
                            color="white"
                            depthWrite={true}
                            emissive="white"
                            emissiveIntensity={0.5}
                            roughness={0.2}
                        />
                        <Outlines thickness={3} color="#EE7037" />
                    </mesh>
                )}

                <Plane
                    args={[1000, 1000]}
                    rotation={[-Math.PI / 2, 0, 0]}
                    position={[0, -0.1, 0]}
                    receiveShadow
                    onDoubleClick={() => handleFocus(null)}
                >
                    <meshStandardMaterial
                        color="white"
                        depthWrite={true}
                        emissive="white" // 'self-illumination' to prevent too gray look
                        emissiveIntensity={0.5}
                        roughness={0.2}
                    />
                </Plane>
            </>
        );
    }
);

export default TopDownScene;
