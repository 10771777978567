import axios, { AxiosResponse } from 'axios';
import { API_URL, AUTH_COOKIE_NAME } from 'config';
import { CookieService } from 'services/cookie/CookieService';

import { InVispakUsers } from './InVispakUsers';
import VispakUsersTransformer from './VispakUsersTransformer';

export class VispakUsersService implements InVispakUsers {
    constructor() {
        this.apiUrl = API_URL;
    }

    apiUrl: string | undefined;

    public async loginAndSetCookie(
        email: string,
        password: string
    ): Promise<AuthResponse | null> {
        const cookieExpirationInDays = 5;
        let loginResponse: AuthResponse | null = null;
        let setCookieResponse: string | null = null;

        loginResponse = await this.getAuthToken(email, password);
        if (loginResponse?.token) {
            setCookieResponse = CookieService.setCookieByName(
                AUTH_COOKIE_NAME,
                loginResponse.token,
                {
                    expires: cookieExpirationInDays,
                }
            );
        }

        return loginResponse;
    }

    public async invalidateAuthToken(): Promise<LogoutResponse | null> {
        const token = CookieService.getCookieByName(AUTH_COOKIE_NAME);
        let logoutResponse: LogoutResponse | null = null;

        if (!token) return null;

        try {
            const response: AxiosResponse<LogoutResponse> = await axios.post(
                `${API_URL}/api/vispakUsers/logout`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response?.data?.message) {
                logoutResponse = {
                    message: response.data.message,
                };
                CookieService.removeCookieByName(AUTH_COOKIE_NAME);
            } else {
                // something funny is going on
                console.warn('Warning: Logout response has changed');

                // still try to remove the cookie
                CookieService.removeCookieByName(AUTH_COOKIE_NAME);
            }
        } catch (error) {
            console.error('Error logging out:', error);
        }

        return logoutResponse;
    }

    public async getUserDetailsByToken(token: string): Promise<VispakUser | null> {
        let userDetails: VispakUser | null = null;

        if (!token) return null;

        try {
            const response: AxiosResponse<AuthResponse> = await axios.get(
                `${this.apiUrl}/api/vispakUsers/me`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            userDetails =
                VispakUsersTransformer.transformUserDetailsResponse(response);
        } catch (error) {
            console.error('Error fetching user details:', error);
        }

        return userDetails;
    }

    private async getAuthToken(
        email: string,
        password: string
    ): Promise<AuthResponse | null> {
        const reqBody = {
            email,
            password,
        };

        let loginResponse: AuthResponse | null = null;

        try {
            const response: AxiosResponse<AuthResponse> = await axios.post(
                `${this.apiUrl}/api/vispakUsers/login`,
                reqBody
            );
            loginResponse =
                VispakUsersTransformer.transformAuthResponse(response);
        } catch (error) {
            console.error('Error logging in:', error);
        }

        return loginResponse;
    }

    public async getLinkForPasswordReset(
        email: string,
    ): Promise<ForgotPasswordResponse | null> {
        const reqBody = {
            email,
        };

        try {
            const response: AxiosResponse<ForgotPasswordResponse> = await axios.post(
                `${this.apiUrl}/api/vispakUsers/forgot-password`,
                reqBody,
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                    }
                });
            return response.data;
        } catch (error: any) {
            throw new Error(
                'Sending email failed:', error?.message
            );
        }
    }
    public async getPasswordResetResponse(
        password: string,
        token: string
    ): Promise<ResetPasswordResponse | null> {
        const reqBody = {
            token,
            password
        };
        try {
            const response: AxiosResponse<ResetPasswordResponse> = await axios.post(
                `${this.apiUrl}/api/vispakUsers/reset-password`,
                reqBody,
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
            return response.data;
        } catch (error: any) {
            throw new Error('Reset password failed:', error?.message);
        }
    }
}
