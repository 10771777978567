import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { useRedirectIfNoId } from 'utils';
import { useReactToPrint } from 'react-to-print';

// mui
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    FormControl,
    IconButton,
    MenuItem,
    Select,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    DownloadOutlined,
    ExitToAppOutlined,
    MenuOutlined,
    RectangleOutlined,
} from '@mui/icons-material';

// ui
import { colors } from 'theming/colors';
import BreadcrumbNavigation from 'components/BreadcrumbNavigation';
import ProductDisplayDetailed from './ProductDisplayDetailed';
import ProductDisplayOverview from './ProductDisplayOverview';
import RequestProductSamplesDialog from './RequestProductSamplesDialog';
import TooltipStyled from 'components/TooltipStyled';

// store
import {
    selectDesignCodeProject,
    selectDesignCodeSpacialSolutions,
    selectSelectedProject,
    selectSpacialSolutions,
} from 'features/projects/state/projectState';

// services
import { PIMService } from 'services/PIMService';

// helpers
import { generateCurrentTimestampString } from 'utils';
import { VispakOfferStatusConst } from 'types/StatusTypes';

// types

enum DisplayTypeConst {
    DETAILED = 'detailed',
    OVERVIEW = 'overview',
}

export enum CategorizationTypeConst {
    BY_ROOM = 'byRoom',
    BY_PRODUCT = 'byProduct',
}

// These values are meant to be used like:
// string.toLowerCase().includes(ProductFeatureName.XX.toLowerCase())
// because the values might not be exact with BE values.
// It's for a bit of future proofing for PIM changes.
// In NOV 2024 the ID field was not recommended to be used that's why
// the feature names are defined here.
//
// TODO: verify this for future possible PIM changes.
export enum ProductFeatureName {
    PLATE_FORMAT = 'plaadi formaat',
    WIDTH_MM = 'laius (mm)',
    LENGTH_MM = 'pikkus (mm)',
    WIDTH_CM = 'laius (cm)',
    LENGTH_CM = 'pikkus (cm)',
    THICKNESS = 'paksus (mm)', // 'Lõnga paksus (mm)' & 'Kulumiskihi paksus (mm)' also exists
    REFINEMENT = 'pinnaviimistlus',
    CO2 = 'co2',
    COLOR = 'värvitoon',
}

export interface ProductListViewVariation {
    roomName: string;
    roomId: string;
    variationId: string;
    variationName: string;
    variationData: any; // Plates object from variation.data
    variationSnapshot: Snapshot | null;
    solutionName: string;
    solutionId: string;
}

export interface ProductListViewCategorizationType {
    id: CategorizationTypeConst.BY_ROOM | CategorizationTypeConst.BY_PRODUCT;
    name: string; // translated text
}

const ProductListView = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const componentRef = useRef(null);

    const categorizationTypes = [
        {
            id: CategorizationTypeConst.BY_ROOM,
            name: t('views.productList.byRoom'),
        },
        {
            id: CategorizationTypeConst.BY_PRODUCT,
            name: t('views.productList.byProduct'),
        },
    ];

    // TODO: tooltip for selected display type
    const displayTypes = [
        {
            id: DisplayTypeConst.DETAILED,
            name: t('views.productList.displayDetailed'),
        },
        {
            id: DisplayTypeConst.OVERVIEW,
            name: t('views.productList.displayOverview'),
        },
    ];

    // local state

    const [selectedCategorizationType, setSelectedCategorizationType] =
        useState<(typeof categorizationTypes)[0]>(categorizationTypes[0]);

    const [activeDisplay, setActiveDisplay] = useState<DisplayTypeConst>(
        DisplayTypeConst.DETAILED
    );

    const [pimProducts, setPimProducts] = useState<PIMProduct[]>([]);

    const [isProductsLoading, setIsProductsLoading] = useState<boolean>(false);

    const [isPdfGenerating, setIsPdfGenerating] = useState<boolean>(false);

    const [selectedSamples, setSelectedSamples] = useState<
        { sampleId: string; productId: string }[]
    >([]);

    const [isRequestSamplesModalOpen, setIsRequestSamplesModalOpen] =
        useState<boolean>(false);

    // for resetting product sample input dropdown
    const [resetTrigger, setResetTrigger] = useState(0);

    // properties

    const designCodeProject = useSelector(selectDesignCodeProject);
    const selectedProject = useSelector(selectSelectedProject);
    const currentProject = selectedProject || designCodeProject;

    // memoized selector
    const userSpacialSolutions = useSelector(
        selectSpacialSolutions,
        shallowEqual
    );

    // memoized selector
    const designCodeSpacialSolutions = useSelector(
        selectDesignCodeSpacialSolutions
    );

    // get spacial solutions either from design code project or user session project
    const spacialSolutions = selectedProject
        ? userSpacialSolutions
        : designCodeSpacialSolutions;

    const redirectIfNoId = useRedirectIfNoId(
        currentProject ? [currentProject] : []
    );

    // get all variations
    // also filter out deleted rooms and variations
    const allPopulatedVariations: ProductListViewVariation[] | undefined =
        useMemo(() => {
            const processedVariations =
                spacialSolutions
                    .flatMap((solution) =>
                        solution.rooms
                            ?.filter((room) => !room.deleted)
                            ?.flatMap((room) =>
                                room.variations
                                    ?.filter((variation) => !variation.deleted)
                                    ?.map((variation) => ({
                                        roomName: room.name,
                                        roomId: room.id,
                                        variationId: variation.id,
                                        variationName: variation.name,
                                        variationData: variation.data
                                            ? JSON.parse(variation.data)
                                            : null,
                                        // get the latest snapshot if any
                                        variationSnapshot: variation?.snapshots
                                            ? variation.snapshots.reduce(
                                                  (latest, current) =>
                                                      new Date(
                                                          current.createdAt
                                                      ) >
                                                      new Date(latest.createdAt)
                                                          ? current
                                                          : latest,
                                                  variation.snapshots[0]
                                              )
                                            : null,
                                        solutionName: solution.name,
                                        solutionId: solution.id,
                                    }))
                            )
                    )
                    ?.filter(
                        (variation): variation is ProductListViewVariation =>
                            variation?.variationData?.plates.length
                    ) || [];

            return processedVariations;
        }, [spacialSolutions]);

    // features

    const handleDetailedDisplay = () => {
        setActiveDisplay(DisplayTypeConst.DETAILED);
    };

    const handleOverviewDisplay = () => {
        setActiveDisplay(DisplayTypeConst.OVERVIEW);
    };

    const handleAfterPrint = React.useCallback(() => {
        setIsPdfGenerating(false);
    }, []);

    const handleBeforePrint = React.useCallback(() => {
        setIsPdfGenerating(true);
        return Promise.resolve();
    }, []);

    const onDownloadPDF = useReactToPrint({
        contentRef: componentRef,
        documentTitle: `product-list-${generateCurrentTimestampString()}`,
        onAfterPrint: handleAfterPrint,
        onBeforePrint: handleBeforePrint,
        // custom page style that forces scaling & font-size
        pageStyle: `
            @page {
                size: auto;
                margin-top: 10mm;
                margin-bottom: 10mm;
            }
            @media print {
                html, body {
                    font-size: 10px !important;
                    -webkit-print-color-adjust: exact !important;
                    print-color-adjust: exact !important;
                    transform: scale(1.0);
                    transform-origin: top left;
                }
            }
        `,
    });

    const handlePrintClick = (event: React.MouseEvent) => {
        event.preventDefault();
        onDownloadPDF();
    };

    const onRequestSamples = () => {
        setIsRequestSamplesModalOpen(true);
    };

    const resetSelectedSamples = () => {
        setSelectedSamples([]);
        // increment trigger to force reset children component's
        // product sample input dropdown local state setLocalSelectedSample()
        setResetTrigger((prev) => prev + 1);
    };

    const onAddNewSample = ({
        productId,
        sampleId,
    }: {
        productId: string;
        sampleId: string;
    }) => {
        setSelectedSamples((prev) => {
            // remove any existing sample for this product
            const filtered = prev.filter(
                (sample) => sample.productId !== productId
            );
            // add the new sample
            return [...filtered, { sampleId, productId }];
        });
    };

    const onRemoveSample = ({
        productId,
        sampleId,
    }: {
        productId: string;
        sampleId: string;
    }) => {
        setSelectedSamples((prev) =>
            prev.filter(
                (sample) =>
                    !(
                        sample.productId === productId &&
                        sample.sampleId === sampleId
                    )
            )
        );
    };

    // side effects

    // auth check
    useEffect(() => {
        redirectIfNoId();
    }, [redirectIfNoId]);

    // Request PIM data when variations change
    // NB! Always use memoized selectors for useEffect dependencies
    // allPopulatedVariations > depends on spacialSolutions > depends on
    // userSpacialSolutions or designCodeSpacialSolutions which are memoized selectors
    // If not using memoized selectors then slow infinite loop will start eating up your CPU
    useEffect(() => {
        if (allPopulatedVariations) {
            (async () => {
                setIsProductsLoading(true);

                const pimIds: string[] = allPopulatedVariations.flatMap(
                    (variation: ProductListViewVariation) =>
                        variation.variationData.plates.flatMap(
                            (plate: any) => plate.pimProductId
                        )
                );

                // remove duplicates
                const uniquePimIds = Array.from(new Set(pimIds));

                // on refresh the side effect gets so far
                if (!uniquePimIds?.length) return;

                const pimService = new PIMService();
                let products: PIMProductsResponse | undefined;

                if (designCodeProject) {
                    await pimService
                        .getProductsByIds(
                            uniquePimIds,
                            designCodeProject.designCode
                        )
                        .then((fetchedProducts) => {
                            products = fetchedProducts;
                        })
                        .catch(() => {
                            setIsProductsLoading(false);
                        });
                } else {
                    await pimService
                        .getProductsByIds(uniquePimIds)
                        .then((fetchedProducts) => {
                            products = fetchedProducts;
                        })
                        .catch(() => {
                            setIsProductsLoading(false);
                        });
                }

                if (products?.docs?.length) {
                    setPimProducts(products.docs);
                }

                setIsProductsLoading(false);
            })();
        }
    }, [
        allPopulatedVariations,
        designCodeProject,
        selectedCategorizationType,
        activeDisplay,
    ]);

    // add (pdf) print styles
    useEffect(() => {
        const style = document.createElement('style');
        style.innerHTML = `
            @media print {
                /* general print margins */
                @page {
                    margin-top: 10mm;
                    margin-bottom: 10mm;
                }

                /* variation container spacing */
                [data-variation-container] {
                    margin-bottom: 10mm !important;
                    padding-bottom: 10mm !important;
                }

                /* prevent overlapping in product items */
                .product-item-details-box {
                    display: flex !important;
                    flex-direction: row !important;
                    // break-inside: avoid !important;
                    // page-break-inside: avoid !important;
                    margin: 5mm 0 !important;
                    clear: both !important;
                }

                /* ensure text content stays on left */
                .product-item-details {
                    flex: 1 1 70% !important;
                    max-width: 70% !important;
                    float: left !important;
                    clear: left !important;
                }

                /* ensure image stays on right */
                .product-item-image-box {
                    flex: 0 0 30% !important;
                    max-width: 30% !important;
                    float: right !important;
                    clear: right !important;
                    padding: 0 !important;
                    margin: 0 !important;
                }

                .product-item-image {
                    max-width: 100% !important;
                    height: auto !important;
                }

                /* existing classes */
                .no-break-inside {
                    page-break-inside: avoid;
                }
            }
        `;
        document.head.appendChild(style);

        return () => {
            document.head.removeChild(style);
        };
    }, []);

    return (
        <>
            <Divider />
            <Box component="div" ref={componentRef}>
                {/* Product list header */}
                <Stack
                    sx={styles.productListHeader}
                    className="no-break-inside"
                    id="product-list-header"
                >
                    <Box component="div" sx={styles.headerNavigation}>
                        <BreadcrumbNavigation />
                    </Box>

                    <Box component="div" sx={styles.headerMainInfo}>
                        <Box component="div" sx={styles.headerMainInfoLeftSide}>
                            <Stack>
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        color: colors.fGrey,
                                    }}
                                >
                                    {t('visualOffer').toUpperCase() + ':'}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '15px',
                                        color: colors.black,
                                        fontWeight: 600,
                                    }}
                                >
                                    {currentProject?.name}
                                </Typography>
                            </Stack>
                            <Stack>
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        color: colors.fGrey,
                                    }}
                                >
                                    {t(
                                        'views.projectList.thDesigner'
                                    ).toUpperCase() + ':'}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '15px',
                                        color: colors.black,
                                        fontWeight: 400,
                                    }}
                                >
                                    {currentProject?.author?.name}
                                </Typography>
                            </Stack>
                            <Stack>
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        color: colors.fGrey,
                                    }}
                                >
                                    {t(
                                        'views.projectList.thAddress'
                                    ).toUpperCase() + ':'}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '15px',
                                        color: colors.black,
                                        fontWeight: 400,
                                    }}
                                >
                                    {currentProject?.odooLead?.address}
                                </Typography>
                            </Stack>
                        </Box>

                        <Box
                            component="div"
                            sx={styles.headerMainInfoRightSide}
                        >
                            <IconButton
                                onClick={() => {
                                    navigate(-1);
                                }}
                                sx={getIconButtonStyle(false)}
                                disabled={false}
                            >
                                <ExitToAppOutlined />
                                <Typography sx={styles.headerButtonText}>
                                    {t(`back`)}
                                </Typography>
                            </IconButton>
                        </Box>
                    </Box>
                </Stack>

                {/* Product list actions */}
                <Box component="div" className="no-break-inside">
                    <Typography sx={styles.productListActionsTitle}>
                        {t('views.productList.categorizeBy')}
                    </Typography>
                </Box>
                <Box component="div" sx={styles.productListActions}>
                    <Box component="div" sx={styles.productListActionsLeftSide}>
                        {/* Input Select for categorization */}
                        <Box
                            component="div"
                            sx={styles.productListActionsInputSelect}
                        >
                            <FormControl
                                variant="standard"
                                fullWidth
                                sx={styles.actionInputSelectFormControl}
                            >
                                <Select
                                    labelId="product-list-select-label"
                                    id="product-list-select"
                                    value={selectedCategorizationType.id}
                                    onChange={(e) => {
                                        resetSelectedSamples();
                                        setSelectedCategorizationType(
                                            categorizationTypes.find(
                                                (type) =>
                                                    type.id === e.target.value
                                            ) || categorizationTypes[0]
                                        );
                                    }}
                                    size="small"
                                    sx={{
                                        minHeight: '30px',
                                        paddingY: '4px',
                                        '& .MuiSelect-select': {
                                            paddingY: '4px',
                                            display: 'flex',
                                            alignItems: 'center',
                                        },
                                    }}
                                >
                                    {categorizationTypes.map((type) => (
                                        <MenuItem
                                            key={type.id}
                                            value={type.id}
                                            sx={{
                                                paddingY: '4px',
                                                fontSize: '0.875rem',
                                            }}
                                        >
                                            {type.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>

                        {/* Detailed view IconButton*/}
                        <Box
                            component="div"
                            sx={{
                                ...styles.productListActionsIcon,
                                marginLeft: '0.4rem',
                            }}
                        >
                            <IconButton
                                onClick={handleDetailedDisplay}
                                sx={{
                                    ...getIconButtonStyle(false),
                                    color:
                                        activeDisplay ===
                                        DisplayTypeConst.DETAILED
                                            ? colors.orange
                                            : colors.black,
                                }}
                            >
                                <RectangleOutlined />
                            </IconButton>
                        </Box>

                        {/* Overview IconButton*/}
                        <Box component="div" sx={styles.productListActionsIcon}>
                            <IconButton
                                onClick={handleOverviewDisplay}
                                sx={{
                                    ...getIconButtonStyle(false),
                                    color:
                                        activeDisplay ===
                                        DisplayTypeConst.OVERVIEW
                                            ? colors.orange
                                            : colors.black,
                                }}
                            >
                                <MenuOutlined />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box
                        component="div"
                        sx={styles.productListActionsRightSide}
                    >
                        <IconButton
                            onClick={handlePrintClick}
                            disabled={isProductsLoading}
                            sx={getIconButtonStyle(false)}
                        >
                            <DownloadOutlined />
                            <Typography
                                sx={{
                                    ...styles.headerButtonText,
                                    opacity: isProductsLoading ? 0.2 : 1,
                                }}
                            >
                                {t(`downloadPDF`)}
                            </Typography>
                        </IconButton>

                        <TooltipStyled
                            title={
                                currentProject?.status !==
                                VispakOfferStatusConst.PUBLIC
                                    ? t(
                                          'views.productList.requestProductSampleTooltipNotPublic'
                                      )
                                    : !selectedSamples.length
                                    ? t(
                                          'views.productList.requestProductSampleTooltipNoSamples'
                                      )
                                    : ''
                            }
                            placement="top-start"
                        >
                            {/* span is for showing tooltip on disabled button */}
                            <span>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={onRequestSamples}
                                    disabled={
                                        !selectedSamples.length ||
                                        currentProject?.status !==
                                            VispakOfferStatusConst.PUBLIC
                                    }
                                    sx={{
                                        padding: '6px 10px',
                                        fontSize: '0.80rem',
                                        textTransform: 'none',
                                        borderRadius: '2px',
                                    }}
                                >
                                    {t('views.productList.requestDemoProduct')}
                                </Button>
                            </span>
                        </TooltipStyled>
                    </Box>
                </Box>

                {/* Product list */}
                {activeDisplay === DisplayTypeConst.DETAILED && (
                    <Box component="div" id="product-display-detailed">
                        <ProductDisplayDetailed
                            currentProject={currentProject}
                            allPopulatedVariations={allPopulatedVariations}
                            pimProducts={pimProducts}
                            categorizationType={selectedCategorizationType}
                            isProductsLoading={isProductsLoading}
                            onAddNewSample={onAddNewSample}
                            onRemoveSample={onRemoveSample}
                            selectedSamples={selectedSamples}
                            resetTrigger={resetTrigger}
                        />
                    </Box>
                )}
                {activeDisplay === DisplayTypeConst.OVERVIEW && (
                    <Box component="div" id="product-display-overview">
                        <ProductDisplayOverview
                            currentProject={currentProject}
                            allPopulatedVariations={allPopulatedVariations}
                            pimProducts={pimProducts}
                            categorizationType={selectedCategorizationType}
                            isProductsLoading={isProductsLoading}
                        />
                    </Box>
                )}

                {/* Dialogs */}

                <RequestProductSamplesDialog
                    isOpen={isRequestSamplesModalOpen}
                    onClose={() => setIsRequestSamplesModalOpen(false)}
                    currentProject={currentProject}
                    selectedSamples={selectedSamples}
                    resetSelectedSamples={resetSelectedSamples}
                    products={pimProducts}
                />

                {/* PDF generating progress */}
                {isPdfGenerating && (
                    <Box component="div" sx={styles.pdfGeneratingProgress}>
                        <CircularProgress />
                    </Box>
                )}
            </Box>
        </>
    );
};

export default ProductListView;

const styles = {
    // Product list header
    productListHeader: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',

        marginLeft: 0,
        marginRight: 0,
        width: '100%',
        boxSizing: 'border-box',
    },
    headerNavigation: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0.5rem 5rem',
        boxSizing: 'border-box',
        width: '100%',
    },
    headerMainInfo: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 2,

        width: '100%',
        padding: '1rem 5rem 1rem 5rem',

        backgroundColor: colors.lGrey,
        marginBottom: 1,
        boxSizing: 'border-box',
    },
    headerMainInfoLeftSide: {
        display: 'flex',
        flexFlow: 'row wrap',
        gap: { xs: '0 1rem', md: '0 2rem', lg: '0 4rem' },
    },
    headerMainInfoRightSide: {
        display: 'flex',
        alignItems: 'center',
        gap: 2,
    },
    headerButtonText: {
        marginLeft: '0.5rem',
        fontSize: '1rem',
        color: colors.black,
    },

    // Product list actions
    productListActionsTitle: {
        fontSize: '0.6rem',
        color: colors.black,
        padding: '0.5rem 5rem 0rem 5rem',
    },
    productListActions: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 5rem 1rem 5rem',
    },
    productListActionsLeftSide: {
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'center',
        gap: 0,
    },
    productListActionsRightSide: {
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'center',
        gap: 2,
    },
    productListActionsInputSelect: {
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'center',

        width: '10rem',
    },
    actionInputSelectFormControl: {
        marginY: 0,
        '& .MuiInput-underline:before': {
            borderBottom: `1px solid ${colors.borderGrey}`,
        },
        '& .MuiInput-underline:after': {
            borderBottom: `1px solid ${colors.borderGrey}`,
        },
        '& .MuiInput-underline:hover:before': {
            borderBottom: `1px solid ${colors.borderGrey}`,
        },
        '& .MuiSelect-select': {
            paddingY: '4px',
            display: 'flex',
            alignItems: 'center',
        },
    },
    productListActionsIcon: {
        display: 'flex',
        alignItems: 'center',
    },

    // PDF generating loader
    pdfGeneratingProgress: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        zIndex: 9999,
    },
};

const getIconButtonStyle = (isDisabled: boolean) => ({
    opacity: isDisabled ? 0.2 : 1,
    color: colors.black,
    '&:hover': {
        backgroundColor: 'transparent',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
    },
    pointerEvents: isDisabled ? 'none' : 'auto',
});
