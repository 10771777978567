export const API_URL = process.env.REACT_APP_API_URL;

export const AUTH_COOKIE_NAME = 'token';

export const DEFAULT_PAGINATION_LIMIT = 10;
export const MAX_NR_OF_PLATES_ON_MOODBOARD = 10;

export const PLACEHOLDER_IMAGE_URL = '/placeholder.jpg';
export const PLACEHOLDER_IMAGE_FOR_VARIATION = '/placeholderVariation.png';
export const LOGIN_IMAGE_URL = '/img/floorin-login-new.png';
export const COMPARE_VIEW_BLANK_IMAGE_URL = '/img/vispak_compare_view_blank.png';

export const DESIGN_CODE_URL_PARAM = 'designCode';
export const PROJECTS_SEARCH_QUERY_URL_PARAM = 'search';
