import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { Close as CloseIcon } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';

// ui
import TextField from 'components/base/TextField';
import Button from 'components/base/Button';

// store
import {
    selectIsLoading,
    updateRoomThunk,
    selectDesignCodeProject,
} from 'features/projects/state/projectState';

// types
import { AppDispatch } from 'store';

export interface NotesDialogProps {
    currentRoom: Room | undefined;
    roomId: string;
    isNotesDialogOpen: boolean;
    onCloseNotesDialog: () => void;
}

const NotesDialog = (props: NotesDialogProps) => {
    // hooks

    const dispatch: AppDispatch = useDispatch();
    const { t: translate } = useTranslation();
    const theme = useTheme();

    // properties

    const designCodeProject = useSelector(selectDesignCodeProject);
    const isLoadingProjectState = useSelector(selectIsLoading);
    const isEditMode =
        props.currentRoom && props.currentRoom.comments?.length > 0;

    // local state

    const [roomName, setRoomName] = useState('');
    const [roomComment, setRoomComment] = useState('');

    // features

    const handleSubmit = async () => {
        const updateData: Partial<Room> = {
            comments: [
                {
                    comment: roomComment,
                },
            ],
        };
        await dispatch(updateRoomThunk({ id: props.roomId, updateData }))
            .unwrap()
            .then(() => {
                props.onCloseNotesDialog();
            })
            .catch((error) => {
                console.error('Error modifying room comment:', error);
            });
    };

    const handleClose = () => {
        props.onCloseNotesDialog();
    };

    // side effects

    useEffect(() => {
        if (props.currentRoom) {
            const comment: UserComment | undefined = props.currentRoom.comments
                ?.length
                ? props.currentRoom.comments[0]
                : undefined;

            comment ? setRoomComment(comment.comment) : setRoomComment('');
        }
        // Rerender the component after opening modal!
    }, [props.currentRoom, props.isNotesDialogOpen]);

    // side effects

    useEffect(() => {
        if (props.currentRoom) {
            setRoomName(props.currentRoom.name);
        }
    }, [props.currentRoom, props.isNotesDialogOpen]);

    return (
        <Dialog open={props.isNotesDialogOpen} onClose={handleClose}>
            {/* Right top close button */}
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.secondary.main,
                }}
            >
                <CloseIcon />
            </IconButton>

            {/* Title */}
            <Box component="div" sx={styles.titleBox}>
                <DialogTitle>
                    {isEditMode
                        ? translate(`views.project.editNotes`)
                        : translate(`views.project.addNotes`)}
                </DialogTitle>
            </Box>

            {/* Content */}
            <DialogContent>
                <TextField
                    disabled={true}
                    variant="standard"
                    label={translate(`views.project.roomName`)}
                    fullWidth
                    rows={4}
                    sx={{ marginTop: '1rem' }}
                    value={roomName}
                ></TextField>
                <TextField
                    variant="standard"
                    label={translate(`views.project.notes`)}
                    placeholder={translate(`views.project.notes`)}
                    fullWidth
                    multiline
                    rows={6}
                    sx={{ marginTop: '2rem' }}
                    onChange={(event) => {
                        setRoomComment(event.target.value);
                    }}
                    value={roomComment}
                ></TextField>
            </DialogContent>

            {/* Actions */}
            <DialogActions sx={styles.dialogActions}>
                <Button
                    sx={styles.buttonCancel}
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={() => handleClose()}
                >
                    {translate(`cancel`)}
                </Button>
                <Button
                    sx={styles.buttonSubmit}
                    variant={'contained'}
                    type="submit"
                    onClick={handleSubmit}
                    disabled={
                        !roomName ||
                        !roomComment ||
                        isLoadingProjectState ||
                        !!designCodeProject?.id
                    }
                >
                    {isLoadingProjectState ? (
                        <CircularProgress size={20} />
                    ) : (
                        translate(`submit`)
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NotesDialog;

const styles = {
    titleBox: {
        marginRight: '2rem',
    },
    dialogActions: {
        justifyContent: 'flex-end',
        gap: '0.4rem',
        margin: '0px 0.5rem 0.5rem 0px',
    },
    buttonCancel: {
        height: '2.8rem',
    },
    buttonSubmit: {
        height: '2.8rem',
    },
};
