import { combineReducers, configureStore } from '@reduxjs/toolkit';

import authSlice, {
    AuthState,
} from '../features/authentication/state/authState';
import localizationSlice, {
    LocalizationState,
} from '../features/localization/state/localizationState';
import projectState, {
    ProjectState,
} from '../features/projects/state/projectState';
import moodboardState, { MoodboardState } from 'features/moodboard/moodboardState';
import pimSlice, { PIMState } from 'features/pim/pimSlice';
import materialPickerReducer, { MaterialPickerState } from 'features/materialPicker/materialPickerSlice';

// Define RootState with all slices
export interface RootState {
    auth: AuthState;
    localization: LocalizationState;
    project: ProjectState;
    moodboard: MoodboardState;
    pim: PIMState;
    materialPicker: MaterialPickerState;
}

export type AppDispatch = typeof store.dispatch; // Define AppDispatch type

// Combine reducers and let TypeScript infer the type
const rootReducer = combineReducers({
    auth: authSlice,
    localization: localizationSlice,
    project: projectState,
    moodboard: moodboardState,
    pim: pimSlice,
    materialPicker: materialPickerReducer,
});

// Create the store with the combined reducer
const store = configureStore({
    reducer: rootReducer,
});

export default store;
