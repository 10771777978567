import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// config
import { AUTH_COOKIE_NAME, LOGIN_IMAGE_URL } from 'config';
import { appPath } from 'routes';

// mui
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';

// ui
import Button from 'components/base/Button';
import TextField from 'components/base/TextField';
import Checkbox from 'components/base/Checkbox';
import Link from 'components/base/text/Link';
import Alert from 'components/base/Alert';

// store
import {
    setUserDetails,
    selectUserDetails,
} from 'features/authentication/state/authState';
import {
    selectDesignCodeProject,
    setDesignCodeProject,
} from 'features/projects/state/projectState';

// services
import { CookieService } from 'services/cookie/CookieService';
import { VispakUsersService } from 'services/vispakUsers/VispakUsersService';

export interface LoginViewProps {
    error?: boolean;
}

const LoginView = (props: LoginViewProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // local state

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [showError, setShowError] = useState(false);

    // properties

    const isDesignCodeProject = useSelector(selectDesignCodeProject);
    const vispakUsersService = new VispakUsersService();
    const userDetails = useSelector(selectUserDetails);
    const authCookie = CookieService.getCookieByName(AUTH_COOKIE_NAME);

    // features

    const handleLogin = async () => {
        const loginResponse = await vispakUsersService.loginAndSetCookie(
            username,
            password
        );

        if (loginResponse) {
            setShowError(false);
            dispatch(setUserDetails(loginResponse.user));
        } else {
            setShowError(true);
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleLogin();
        }
    };

    const redirectIfNoId = useCallback(async () => {
        if (authCookie) {
            // if design code project is present, clear it
            // as only one session (user or design code) can be active at a time
            if (isDesignCodeProject) {
                dispatch(setDesignCodeProject(null));
            }

            if (!userDetails?.id) {
                const userDetails =
                    await vispakUsersService.getUserDetailsByToken(authCookie);
                if (userDetails) {
                    dispatch(setUserDetails(userDetails));
                } // TODO: possible case where auth cookie is invalid(modified), and need to delete it
            } else {
                navigate(appPath.projects);
            }
        }
        // no auth cookie
        // user is already in the right place to enter details for '/login'

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authCookie, userDetails]);

    // side effects

    useEffect(() => {
        (async function () {
            await redirectIfNoId();
        })();
    }, [redirectIfNoId, userDetails]);

    // dont show login form if authcookie present
    if (!userDetails && authCookie) return null;

    return (
        <Box component={'div'} sx={style.parentBox}>
            <Box component={'div'} sx={style.left}>
                <img
                    src={LOGIN_IMAGE_URL}
                    alt="floorin"
                    style={{
                        objectFit: 'cover',
                        objectPosition: 'left',
                        width: '100%',
                        height: '100%',
                    }}
                />
            </Box>
            <Box component={'div'} sx={style.right}>
                <h2>{t(`views.login.header`)}</h2>
                <Alert
                    sx={{
                        ...style.alertBox,
                        visibility: !showError ? 'hidden' : 'unset',
                    }}
                    severity="error"
                >
                    {t(`views.login.loginFormError`)}
                </Alert>
                <Box
                    component={'div'}
                    sx={style.loginFields}
                    onKeyPress={handleKeyPress}
                >
                    <TextField
                        error={showError}
                        sx={style.loginTextField}
                        label={t(`views.login.username`)}
                        variant="standard"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        error={showError}
                        sx={style.loginTextField}
                        label={t(`views.login.password`)}
                        variant="standard"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Box component={'div'} sx={style.loginRowField}>
                        <FormControlLabel
                            label={t(`views.login.rememberMe`)}
                            control={
                                <Checkbox
                                    checked={rememberMe}
                                    onChange={(e) =>
                                        setRememberMe(e.target.checked)
                                    }
                                />
                            }
                        />
                        <Link sx={style.loginForgotPassword} to="/login/forgot-password">
                            {t(`views.login.forgotPassword`)}
                        </Link>
                    </Box>
                    <Button onClick={handleLogin}>
                        {t(`views.login.login`)}
                    </Button>
                </Box>
                <Link to="/login/code">
                    {t(`views.login.enterWithDesignCode`)}
                </Link>
            </Box>
        </Box>
    );
};

export default LoginView;

const style: any = {
    parentBox: {
        display: 'flex',
        height: '91.65%',
    },
    left: {
        display: 'flex',
        flex: 2,
    },
    right: {
        display: 'flex',
        flex: 1,
        gap: '3rem',
        align: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },

    loginFields: {
        display: 'flex',
        gap: '1rem',
        flexDirection: 'column',
        width: '70%',
    },
    alertBox: {
        width: '70%',
    },

    loginTextField: {
        width: '100%',
    },
    loginRowField: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    loginForgotPassword: {
        marginLeft: 'auto',
    },
};
