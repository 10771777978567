import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { appPath } from 'routes';

// config
import {
    AUTH_COOKIE_NAME,
    LOGIN_IMAGE_URL,
} from 'config';

// mui
import Box from '@mui/material/Box';

// ui
import Button from 'components/base/Button';
import TextField from 'components/base/TextField';
import Link from 'components/base/text/Link';
import Alert from 'components/base/Alert';

// services
import { VispakUsersService } from '../../../services/vispakUsers/VispakUsersService';
import { CookieService } from '../../../services/cookie/CookieService';

export interface ResetPasswordViewViewProps {
    error?: boolean;
}

const passwordRegex =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[.#?!@$%^&*-:;()+]).{8,}$/;

const ResetPasswordView = (props: ResetPasswordViewViewProps) => {
    const { t: translate } = useTranslation();
    const navigate = useNavigate();

    // local state
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [showError, setShowError] = useState({
        mismatchError: false,
        validationError: false,
        responseError: false,
    });
    const [successMessage, setSuccessMessage] = useState(false);
    const [token, setToken] = useState('');

    // url parameter
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const tokenFromUrl = urlParams.get('token');

    // properties
    const authCookie = CookieService.getCookieByName(AUTH_COOKIE_NAME);

    // features
    const handlePasswordReset = async () => {
        if (!password || !token) {
            return;
        }

        setSuccessMessage(false);
        setShowError((prevState) => ({
            ...prevState,
            mismatchError: false,
            validationError: false,
            responseError: false,
        }));

        if (!passwordRegex.test(password)) {
            setShowError((prevState) => ({
                ...prevState,
                validationError: true,
            }));
            return;
        }

        if (password !== passwordConfirm) {
            setShowError((prevState) => ({
                ...prevState,
                mismatchError: true,
            }));
            return;
        }

        const vispakUsersService = new VispakUsersService();

        try {
            const response = await vispakUsersService.getPasswordResetResponse(
                password,
                token
            );
            if (response) {
                setSuccessMessage(true);
            } else {
                setShowError((prevState) => ({
                    ...prevState,
                    responseError: true,
                }));
            }
        } catch (error: any) {
            setShowError((prevState) => ({
                ...prevState,
                responseError: true,
            }));
        }
        return;
    };

    const redirectIfPasswordResetSuccess = useCallback(async () => {
        // password reset success, user is not logged in
        // redirect to login page after 5s, when the success msg is shown
        if (successMessage) {
            setTimeout(() => navigate(appPath.login), 5000);
        }
    }, [successMessage, navigate]);

    const redirectIfNoAuthCookie = async () => {
        // if logged in, redirect to projects
        if (authCookie) {
            navigate(appPath.projects);
        }
    };

    // side effects
    useEffect(() => {
        (async function () {
            await redirectIfNoAuthCookie();
        })();
    }, [redirectIfNoAuthCookie]);

    // get token from url parameters
    useEffect(() => {
        if (tokenFromUrl) {
            setToken(tokenFromUrl);
        }
    }, [tokenFromUrl]);

    // redirect if reset password successful
    useEffect(() => {
        (async function () {
            await redirectIfPasswordResetSuccess();
        })();
    }, [redirectIfPasswordResetSuccess]);

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handlePasswordReset();
        }
    };

    return (
        <Box component={'div'} sx={style.parentBox}>
            <Box component={'div'} sx={style.left}>
                <img
                    src={LOGIN_IMAGE_URL}
                    alt="floorin"
                    style={{
                        objectFit: 'cover',
                        objectPosition: 'left',
                        width: '100%',
                        height: '100%',
                    }}
                />
            </Box>
            <Box component={'div'} sx={style.right}>
                <h2>{translate(`views.resetPassword.header`)}</h2>
                <Alert
                    sx={{
                        ...style.alertBox,
                        visibility:
                            successMessage || showError.responseError
                                ? 'unset'
                                : 'hidden',
                    }}
                    severity={successMessage ? 'success' : 'error'}
                >
                    {successMessage &&
                        translate(`views.resetPassword.passwordResetSuccess`)}
                    {!successMessage && showError.responseError && (
                        <>
                            {translate(
                                `views.resetPassword.passwordResetResponseError`
                            )}{' '}
                            <Link to="/login/forgot-password">
                                {translate(`views.login.forgotPassword`)}
                            </Link>
                        </>
                    )}
                </Alert>
                <Box
                    component={'div'}
                    sx={style.forgotPasswordFields}
                    onKeyPress={handleKeyPress}
                >
                    <TextField
                        id="password-textfield"
                        error={
                            showError.mismatchError ||
                            showError.validationError ||
                            showError.responseError
                        }
                        sx={style.forgotPasswordTextField}
                        label={translate(`views.resetPassword.password`)}
                        variant="standard"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        helperText={
                            showError.validationError &&
                            !passwordRegex.test(password) && (
                                <span style={{ color: 'red' }}>
                                    {translate(
                                        `views.resetPassword.passwordValidationError`
                                    )}
                                </span>
                            )
                        }
                    />
                    <TextField
                        id="password-confirm-textfield"
                        error={
                            showError.mismatchError ||
                            showError.validationError ||
                            showError.responseError
                        }
                        sx={style.forgotPasswordTextField}
                        label={translate(`views.resetPassword.confirmPassword`)}
                        variant="standard"
                        type="password"
                        value={passwordConfirm}
                        onChange={(e) => setPasswordConfirm(e.target.value)}
                        helperText={
                            showError.mismatchError &&
                            password !== passwordConfirm && (
                                <span style={{ color: 'red' }}>
                                    {translate(
                                        `views.resetPassword.passwordMismatchError`
                                    )}
                                </span>
                            )
                        }
                    />
                    <Button
                        onClick={handlePasswordReset}
                        sx={style.forgotPasswordButton}
                    >
                        {translate(`views.resetPassword.resetPassword`)}
                    </Button>
                </Box>
                <Link to="/login">
                    {translate(`views.login.enterWithAccount`)}
                </Link>
            </Box>
        </Box>
    );
};

export default ResetPasswordView;

const style: any = {
    parentBox: {
        display: 'flex',
        height: '91.65%',
    },
    left: {
        display: 'flex',
        flex: 2,
    },
    right: {
        display: 'flex',
        flex: 1,
        gap: '3rem',
        align: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },

    forgotPasswordFields: {
        display: 'flex',
        gap: '1rem',
        flexDirection: 'column',
        width: '70%',
    },
    alertBox: {
        width: '70%',
    },

    forgotPasswordTextField: {
        width: '100%',
    },
    forgotPasswordButton: {
        height: '2.5rem',
    },
};
