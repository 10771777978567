import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/index';

// define the state interface
export interface MaterialPickerState {
    filteredProducts: PIMProduct[];
    selectedFilterCollections: string[];
    selectedFilterGroups: string[];
    selectedFilterManufacturers: string[];
    selectedFilterSubGroups: string[];
    selectedFilterFeatures: string[];
    searchTerm: string;
    page: number;
    allProducts: PIMProduct[];
    selectedFeatureValues: string[];
    autoCheckedGroupId: string | null;
    autoCheckedSubGroupIds: string[];
}

// initial state
const initialState: MaterialPickerState = {
    filteredProducts: [],
    selectedFilterCollections: [],
    selectedFilterGroups: [],
    selectedFilterManufacturers: [],
    selectedFilterSubGroups: [],
    selectedFilterFeatures: [],
    searchTerm: '',
    page: 1,
    allProducts: [],
    selectedFeatureValues: [],
    autoCheckedGroupId: null,
    autoCheckedSubGroupIds: [],
};

// create the slice
export const materialPickerSlice = createSlice({
    name: 'materialPicker',
    initialState,
    reducers: {
        setFilteredProducts: (state, action: PayloadAction<PIMProduct[]>) => {
            state.filteredProducts = action.payload;
        },
        setSelectedFilterCollections: (state, action: PayloadAction<string[]>) => {
            state.selectedFilterCollections = action.payload;
        },
        setSelectedFilterGroups: (state, action: PayloadAction<string[]>) => {
            state.selectedFilterGroups = action.payload;
        },
        setSelectedFilterManufacturers: (state, action: PayloadAction<string[]>) => {
            state.selectedFilterManufacturers = action.payload;
        },
        setSelectedFilterSubGroups: (state, action: PayloadAction<string[]>) => {
            state.selectedFilterSubGroups = action.payload;
        },
        setSelectedFilterFeatures: (state, action: PayloadAction<string[]>) => {
            state.selectedFilterFeatures = action.payload;
        },
        setSearchTerm: (state, action: PayloadAction<string>) => {
            state.searchTerm = action.payload;
        },
        setPage: (state, action: PayloadAction<number>) => {
            state.page = action.payload;
        },
        // material picker saved products from PIM backend
        setAllProducts: (state, action: PayloadAction<PIMProduct[]>) => {
            state.allProducts = action.payload;
        },
        setSelectedFeatureValues: (state, action: PayloadAction<string[]>) => {
            state.selectedFeatureValues = action.payload;
        },
        setAutoCheckedGroupId: (state, action: PayloadAction<string | null>) => {
            state.autoCheckedGroupId = action.payload;
        },
        setAutoCheckedSubGroupIds: (state, action: PayloadAction<string[]>) => {
            state.autoCheckedSubGroupIds = action.payload;
        },
        resetFilters: (state) => {
            state.selectedFilterCollections = [];
            state.selectedFilterManufacturers = [];
            state.selectedFilterFeatures = [];
            state.selectedFeatureValues = [];
            state.searchTerm = '';
            state.page = 1;
            state.filteredProducts = [];
            state.allProducts = [];
            state.autoCheckedGroupId = null;
            state.autoCheckedSubGroupIds = [];
            state.selectedFilterGroups = [];
            state.selectedFilterSubGroups = [];
        },
    },
});

// export actions
export const {
    setFilteredProducts,
    setSelectedFilterCollections,
    setSelectedFilterGroups,
    setSelectedFilterManufacturers,
    setSelectedFilterSubGroups,
    setSelectedFilterFeatures,
    setSearchTerm,
    setPage,
    setAllProducts,
    setSelectedFeatureValues,
    setAutoCheckedGroupId,
    setAutoCheckedSubGroupIds,
    resetFilters,
} = materialPickerSlice.actions;

// selectors
export const selectMaterialPickerState = (state: RootState) => state.materialPicker;
export const selectFilteredProducts = (state: RootState) => state.materialPicker.filteredProducts;
export const selectSelectedFilterCollections = (state: RootState) => state.materialPicker.selectedFilterCollections;
export const selectSelectedFilterGroups = (state: RootState) => state.materialPicker.selectedFilterGroups;
export const selectSelectedFilterManufacturers = (state: RootState) => state.materialPicker.selectedFilterManufacturers;
export const selectSelectedFilterSubGroups = (state: RootState) => state.materialPicker.selectedFilterSubGroups;
export const selectSelectedFilterFeatures = (state: RootState) => state.materialPicker.selectedFilterFeatures;
export const selectSearchTerm = (state: RootState) => state.materialPicker.searchTerm;
export const selectPage = (state: RootState) => state.materialPicker.page;

// base selector
export const selectAllProductsBase = (state: RootState) => state.materialPicker.allProducts;

// memoized selector
export const selectMemoizedAllProducts = createSelector(
  [selectAllProductsBase],
  (allProducts) => allProducts
);

export const selectSelectedFeatureValues = (state: RootState) => state.materialPicker.selectedFeatureValues;
export const selectAutoCheckedGroupId = (state: RootState) => state.materialPicker.autoCheckedGroupId;
export const selectAutoCheckedSubGroupIds = (state: RootState) => state.materialPicker.autoCheckedSubGroupIds;

export default materialPickerSlice.reducer; 