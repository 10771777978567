import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// config
import {
    AUTH_COOKIE_NAME,
    LOGIN_IMAGE_URL,
} from 'config';

// mui
import Box from '@mui/material/Box';

// ui
import Button from 'components/base/Button';
import TextField from 'components/base/TextField';
import Link from 'components/base/text/Link';
import Alert from 'components/base/Alert';

// services
import { VispakUsersService } from '../../../services/vispakUsers/VispakUsersService';
import { CookieService } from '../../../services/cookie/CookieService';

// config
import { appPath } from 'routes';
import { useNavigate } from 'react-router-dom';

export interface ForgotPasswordViewViewProps {
    error?: boolean;
}
const emailRegex =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

const ForgotPasswordView = (props: ForgotPasswordViewViewProps) => {
    const { t: translate } = useTranslation();
    const navigate = useNavigate();

    // local state
    const [showError, setShowError] = useState({
        validationError: false,
        responseError: false,
    });
    const [email, setEmail] = useState('');
    const [successMessage, setSuccessMessage] = useState(false);

    // properties
    const authCookie = CookieService.getCookieByName(AUTH_COOKIE_NAME);

    // features
    const handleSendLinkToEmail = async () => {
        if (!email) return;

        setShowError((prevState) => ({
            ...prevState,
            validationError: false,
            responseError: false,
        }));
        setSuccessMessage(false);

        if (!emailRegex.test(email)) {
            setShowError((prevState) => ({
                ...prevState,
                validationError: true,
            }));
            return;
        }

        const vispakUsersService = new VispakUsersService();

        try {
            const response =
                await vispakUsersService.getLinkForPasswordReset(email);

            if (response && response.message === 'Success') {
                setSuccessMessage(true);
            } else {
                setShowError((prevState) => ({
                    ...prevState,
                    responseError: true,
                }));
            }
        } catch (error) {
            setShowError((prevState) => ({
                ...prevState,
                responseError: true,
            }));
        }
        return;
    };

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleSendLinkToEmail();
        }
    };

    const redirectIfNoAuthCookie = async () => {
        // if logged in, redirect to projects
        if (authCookie) {
            navigate(appPath.projects);
        }
    };

    // side effects
    useEffect(() => {
        (async function () {
            await redirectIfNoAuthCookie();
        })();
    }, [redirectIfNoAuthCookie]);

    return (
        <Box component={'div'} sx={style.parentBox}>
            <Box component={'div'} sx={style.left}>
                <img
                    src={LOGIN_IMAGE_URL}
                    alt="floorin"
                    style={{
                        objectFit: 'cover',
                        objectPosition: 'left',
                        width: '100%',
                        height: '100%',
                    }}
                />
            </Box>
            <Box component={'div'} sx={style.right}>
                <h2>{translate(`views.forgotPassword.header`)}</h2>
                <Alert
                    sx={{
                        ...style.alertBox,
                        visibility:
                            successMessage || showError.responseError
                                ? 'unset'
                                : 'hidden',
                    }}
                    severity={successMessage ? 'success' : 'error'}
                >
                    {successMessage &&
                        translate(`views.forgotPassword.emailSentSuccess`)}
                    {!successMessage &&
                        showError.responseError &&
                        translate(`views.forgotPassword.emailResponseError`)}
                </Alert>
                <Box
                    component={'div'}
                    sx={style.forgotPasswordFields}
                    onKeyPress={handleKeyPress}
                >
                    <TextField
                        id="email-textfield"
                        error={
                            showError.responseError || showError.validationError
                        }
                        sx={style.forgotPasswordTextField}
                        label={translate(`views.forgotPassword.email`)}
                        variant="standard"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        helperText={
                            showError.validationError &&
                            !emailRegex.test(email) ? (
                                <span style={{ color: 'red' }}>
                                    {translate(
                                        `views.forgotPassword.emailValidationError`
                                    )}
                                </span>
                            ) : (
                                ''
                            )
                        }
                    />
                    <Button
                        onClick={handleSendLinkToEmail}
                        sx={style.forgotPasswordButton}
                    >
                        {translate(`views.forgotPassword.sendLinkToEmail`)}
                    </Button>
                </Box>
                <Link to="/login">
                    {translate(`views.login.enterWithAccount`)}
                </Link>
            </Box>
        </Box>
    );
};

export default ForgotPasswordView;

const style: any = {
    parentBox: {
        display: 'flex',
        height: '91.65%',
    },
    left: {
        display: 'flex',
        flex: 2,
    },
    right: {
        display: 'flex',
        flex: 1,
        gap: '3rem',
        align: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },

    forgotPasswordFields: {
        display: 'flex',
        gap: '1rem',
        flexDirection: 'column',
        width: '70%',
    },
    alertBox: {
        width: '70%',
    },

    forgotPasswordTextField: {
        width: '100%',
    },
    forgotPasswordButton: {
        height: '2.5rem',
    },
};
