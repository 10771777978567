import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    DEFAULT_PAGINATION_LIMIT,
    PROJECTS_SEARCH_QUERY_URL_PARAM,
} from 'config';

// utils
import { isReminderDateWithinWeek, validateInput } from 'utils';
import { appPathConst } from 'routes';

// mui
import {
    Typography,
    Fade,
    Box,
    TextField,
    InputAdornment,
} from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';

// ui
import { colors } from 'theming/colors';
import Button from 'components/base/Button';
import StatusTag from 'components/StatusTag';
import RowOptions from 'components/specific/projectList/RowOptions';
import TooltipStyled from 'components/TooltipStyled';
import AnimatedToggle from 'components/AnimatedToggle';

// store
import {
    selectProjects,
    setSelectedProject,
    selectIsLoading,
    fetchProjects,
} from 'features/projects/state/projectState';
import { AppDispatch } from 'store';
import { selectCurrentLanguage } from 'features/localization/state/localizationState';

// types
import { VispakOfferStatusConst } from 'types/StatusTypes';
import { VispakOfferStatus } from 'types/StatusTypes';

interface OffersTableProps {
    setMenuAnchorElProjectMenu: React.Dispatch<
        React.SetStateAction<null | HTMLElement>
    >;
    setActiveProjectId: React.Dispatch<React.SetStateAction<string>>;
    setNewProjectModalOpen: () => void;
    showArchived: boolean;
    showTimeCritical: boolean;
    handleArchivedToggle: () => void;
    handleTimeCriticalToggle: () => void;
    searchQueryFromUrl: string | null;
    paginationEnabled: boolean;
    setPaginationEnabled: (enabled: boolean) => void;
    fetchProjectsWithPagination: () => void;
}

export default function OffersTable(props: OffersTableProps) {
    const { t } = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();

    // local state

    const [searchFilter, setSearchFilter] = useState('');
    const [showSearchInput, setShowSearchInput] = useState(false);
    const searchInputRef = useRef<HTMLInputElement>(null);

    // properties

    const {
        showArchived,
        showTimeCritical,
        handleArchivedToggle,
        handleTimeCriticalToggle,
        searchQueryFromUrl,
        paginationEnabled,
        setPaginationEnabled,
        fetchProjectsWithPagination,
    } = props;

    const selectedLocale = useSelector(selectCurrentLanguage);
    const isLoading = useSelector(selectIsLoading);
    const projectsRawData = useSelector(selectProjects);
    const projects =
        useSelector(selectProjects)
            .docs?.map((item: VisualOffering, index) => ({
                id: item?.id,
                offer: item?.name,
                offerURL: '',
                address: item?.odooLead?.address,
                client: item?.odooLead?.leadCustomerName,
                designer: item?.author?.name,
                designCode: item?.designCode,
                status: item?.status as VispakOfferStatus,
                lastChanged: item?.lastUpdated
                    ? new Date(item.lastUpdated).toLocaleString(selectedLocale)
                    : '',
                reminderDate: item?.reminderDate || '',
            }))
            .filter((elem) => {
                const lowerCaseFilter = searchFilter?.toLowerCase() || '';

                // If showTimeCritical is set, only show items with reminder dates within a week
                if (showTimeCritical) {
                    return (
                        elem?.reminderDate &&
                        isReminderDateWithinWeek(elem.reminderDate)
                    );
                }

                // Otherwise, apply the search filter
                return (
                    elem?.offer?.toLowerCase()?.includes(lowerCaseFilter) ||
                    elem?.address?.toLowerCase()?.includes(lowerCaseFilter) ||
                    elem?.client?.toLowerCase()?.includes(lowerCaseFilter) ||
                    elem?.designer?.toLowerCase()?.includes(lowerCaseFilter) ||
                    elem?.designCode
                        ?.toLowerCase()
                        ?.includes(lowerCaseFilter) ||
                    // can't search for status atm. because it's not translated
                    // (inProgress, published, archived, deleted) in BE (check <StatusTag />)
                    elem?.status?.toLowerCase()?.includes(lowerCaseFilter)
                );
            }) || [];

    const columns: GridColDef<any[number]>[] = [
        {
            field: 'offer',
            headerName: t(`views.projectList.thOffer`),
            flex: 6,
            renderHeader: (data) => {
                return (
                    <Typography sx={style.headerText}>
                        {data.colDef.headerName}
                    </Typography>
                );
            },
            headerClassName: 'data-grid-custom-header',
            cellClassName: 'data-grid-custom-cell',
            renderCell: (params) => {
                const reminderDate: string | undefined =
                    params.row?.reminderDate;
                const reminderDateString = reminderDate
                    ? new Date(reminderDate).toLocaleDateString(selectedLocale)
                    : '';
                const showWarning =
                    reminderDate && isReminderDateWithinWeek(reminderDate);

                return (
                    <Box component="div" sx={style.cellItem}>
                        {showWarning ? (
                            <TooltipStyled
                                title={
                                    t('views.projectList.reminderDateWarning') +
                                    `: ${reminderDateString}`
                                }
                                placement="top-start"
                            >
                                <Typography
                                    sx={{
                                        ...style.reminderDateWarning,
                                        cursor: 'pointer',
                                        textDecoration: 'underline',
                                    }}
                                >
                                    <span style={{ fontSize: '1.4rem' }}>
                                        !
                                    </span>{' '}
                                    {highlightSearchText(
                                        params.value || '',
                                        searchFilter
                                    )}
                                </Typography>
                            </TooltipStyled>
                        ) : (
                            highlightSearchText(
                                params.value || '',
                                searchFilter
                            )
                        )}
                    </Box>
                );
            },
        },
        {
            field: 'address',
            headerName: t(`views.projectList.thAddress`),
            flex: 5,
            renderHeader: (data) => {
                return (
                    <Typography sx={style.headerText}>
                        {data.colDef.headerName}
                    </Typography>
                );
            },
            headerClassName: 'data-grid-custom-header',
            renderCell: (data) => {
                const reminderDate: string | undefined = data.row?.reminderDate;
                const showWarning =
                    reminderDate && isReminderDateWithinWeek(reminderDate);

                return (
                    <Box component="div" sx={style.cellItem}>
                        {showWarning ? (
                            <Typography sx={style.reminderDateWarning}>
                                {highlightSearchText(
                                    data.value || '',
                                    searchFilter
                                )}
                            </Typography>
                        ) : (
                            highlightSearchText(data.value || '', searchFilter)
                        )}
                    </Box>
                );
            },
        },
        {
            field: 'client',
            headerName: t(`views.projectList.thClient`),
            flex: 3,
            renderHeader: (data) => {
                return (
                    <Typography sx={style.headerText}>
                        {data.colDef.headerName}
                    </Typography>
                );
            },
            headerClassName: 'data-grid-custom-header',
            renderCell: (data) => {
                const reminderDate: string | undefined = data.row?.reminderDate;
                const showWarning =
                    reminderDate && isReminderDateWithinWeek(reminderDate);

                return (
                    <Box component="div" sx={style.cellItem}>
                        {showWarning ? (
                            <Typography sx={style.reminderDateWarning}>
                                {highlightSearchText(
                                    data.value || '',
                                    searchFilter
                                )}
                            </Typography>
                        ) : (
                            highlightSearchText(data.value || '', searchFilter)
                        )}
                    </Box>
                );
            },
        },
        {
            field: 'designer',
            headerName: t(`views.projectList.thDesigner`),
            flex: 3,
            renderHeader: (data) => {
                return (
                    <Typography sx={style.headerText}>
                        {data.colDef.headerName}
                    </Typography>
                );
            },
            headerClassName: 'data-grid-custom-header',
            renderCell: (data) => {
                const reminderDate: string | undefined = data.row?.reminderDate;
                const showWarning =
                    reminderDate && isReminderDateWithinWeek(reminderDate);

                return (
                    <Box component="div" sx={style.cellItem}>
                        {showWarning ? (
                            <Typography sx={style.reminderDateWarning}>
                                {highlightSearchText(
                                    data.value || '',
                                    searchFilter
                                )}
                            </Typography>
                        ) : (
                            highlightSearchText(data.value || '', searchFilter)
                        )}
                    </Box>
                );
            },
        },
        {
            field: 'designCode',
            headerName: t(`views.projectList.code`),
            flex: 3,
            renderHeader: (data) => {
                return (
                    <Typography sx={style.headerText}>
                        {data.colDef.headerName}
                    </Typography>
                );
            },
            headerClassName: 'data-grid-custom-header',
            renderCell: (data) => {
                const reminderDate: string | undefined = data.row?.reminderDate;
                const showWarning =
                    reminderDate && isReminderDateWithinWeek(reminderDate);

                return (
                    <Box component="div" sx={style.cellItem}>
                        {showWarning ? (
                            <Typography sx={style.reminderDateWarning}>
                                {highlightSearchText(
                                    data.value || '',
                                    searchFilter
                                )}
                            </Typography>
                        ) : (
                            highlightSearchText(data.value || '', searchFilter)
                        )}
                    </Box>
                );
            },
        },
        {
            field: 'status',
            headerName: t(`views.projectList.thStatus`),
            flex: 2,
            renderCell: (params) => {
                return (
                    <Box
                        component="div"
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        <StatusTag
                            status={params.value}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: colors.red,
                            }}
                        />
                    </Box>
                );
            },
            renderHeader: (data) => {
                return (
                    <Typography sx={style.headerText}>
                        {data.colDef.headerName}
                    </Typography>
                );
            },
            headerClassName: 'data-grid-custom-header',
        },
        {
            field: 'lastChanged',
            headerName: t(`views.projectList.thLastChanged`),
            flex: 5,
            valueGetter: (value) => {
                return value;
            },
            renderHeader: (data) => {
                return (
                    <Typography sx={style.headerText}>
                        {data.colDef.headerName}
                    </Typography>
                );
            },
            headerClassName: 'data-grid-custom-header',
            renderCell: (data) => {
                const reminderDate: string | undefined = data.row?.reminderDate;
                const showWarning =
                    reminderDate && isReminderDateWithinWeek(reminderDate);

                return (
                    <Box component="div" sx={style.cellItem}>
                        {showWarning ? (
                            <Typography sx={style.reminderDateWarning}>
                                {highlightSearchText(
                                    data.value || '',
                                    searchFilter
                                )}
                            </Typography>
                        ) : (
                            highlightSearchText(data.value || '', searchFilter)
                        )}
                    </Box>
                );
            },
        },
        {
            field: 'options',
            headerName: '',
            flex: 1,
            sortable: false,
            resizable: false,
            align: 'right',
            disableColumnMenu: true,
            renderCell: (data: GridRenderCellParams<any>) => {
                return (
                    <RowOptions
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                            openRowOptionsMenu(event, data)
                        }
                    />
                );
            },
            headerClassName: 'data-grid-custom-header data-grid-options-column',
        },
    ];

    // features

    const openNewProjectDialog = () => {
        props.setNewProjectModalOpen();
    };

    const openRowOptionsMenu = (
        event: React.MouseEvent<HTMLButtonElement>,
        data: GridRenderCellParams<any>
    ) => {
        const projectId = data.id?.toString() || '';
        props.setMenuAnchorElProjectMenu(event.currentTarget);
        props.setActiveProjectId(projectId); // Set the active menu ID to the row ID
    };

    const highlightSearchText = (text: string, searchText: string) => {
        if (!searchText) return text;
        const parts = text?.split(new RegExp(`(${searchText})`, 'gi')) || [];

        return (
            <>
                {parts?.map((part, index) =>
                    part.toLowerCase() === searchText?.toLowerCase() ? (
                        <span
                            key={index}
                            style={{ backgroundColor: '#fff279' }}
                        >
                            {part}
                        </span>
                    ) : (
                        part
                    )
                )}
            </>
        );
    };

    const resetSearch = () => {
        setSearchFilter('');
        setShowSearchInput(false);
        setPaginationEnabled(true);
        fetchProjectsWithPagination();

        // remove search query from url
        if (searchQueryFromUrl) {
            // get current url and remove search parameter
            const url = new URL(window.location.href);
            url.searchParams.delete(PROJECTS_SEARCH_QUERY_URL_PARAM);

            // update browser url without full page reload
            navigate(url.pathname + url.search, { replace: true });
        }
    };

    // side effects

    useEffect(() => {
        if (searchQueryFromUrl && projectsRawData?.docs?.length > 0) {
            const validatedSearchQuery = validateInput(searchQueryFromUrl);
            setPaginationEnabled(false);
            setSearchFilter(validatedSearchQuery);
        }
    }, [
        projectsRawData,
        searchQueryFromUrl,
        setSearchFilter,
        setPaginationEnabled,
        paginationEnabled,
    ]);

    useEffect(() => {
        if (showSearchInput && searchInputRef.current) {
            // slight delay to ensure DOM has updated
            setTimeout(() => {
                searchInputRef.current?.focus();
            }, 100);
        }
    }, [showSearchInput, projectsRawData, paginationEnabled]);

    return (
        <>
            <Box component="div" sx={style.headerBox}>
                <Box component="div">
                    <Typography variant="h5">
                        {t(`views.projectList.header`)}
                    </Typography>
                </Box>

                <Box component="div" sx={style.headerButtons}>
                    {/* Time-critical offers toggle */}
                    <AnimatedToggle
                        isOn={showTimeCritical}
                        onClick={handleTimeCriticalToggle}
                        text={t('views.projectList.timeCritical')}
                        disabled={isLoading || showArchived} // Disable when archived is true
                    />

                    {/* Archived toggle */}
                    <AnimatedToggle
                        isOn={showArchived}
                        onClick={handleArchivedToggle}
                        text={t('views.projectList.archived')}
                        disabled={isLoading}
                    />

                    {/* Search input */}
                    <Box
                        component="div"
                        sx={{
                            ...style.searchInputContainer,
                            padding: '0 8px 0 6px',
                            backgroundColor: searchFilter
                                ? colors.rgba.searchYellow
                                : 'transparent',
                        }}
                    >
                        <Box
                            component="div"
                            sx={style.searchInputVisibleBox}
                            onClick={() => {
                                setShowSearchInput(true);
                                setPaginationEnabled(false);
                            }}
                        >
                            <SearchIcon />
                            <Typography>
                                {t('views.projectList.search')}
                            </Typography>
                        </Box>

                        <Fade in={showSearchInput} timeout={300}>
                            <Box
                                component="div"
                                sx={style.searchInputHiddenBox}
                            >
                                {showSearchInput && (
                                    <TextField
                                        inputRef={searchInputRef}
                                        disabled={isLoading}
                                        placeholder={t(
                                            `views.projectList.searchBarPlaceholder`
                                        )}
                                        variant="outlined"
                                        size="small"
                                        onChange={(event) => {
                                            setSearchFilter(event.target.value);
                                        }}
                                        value={searchFilter}
                                        autoFocus
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        onBlur={() => {
                                            if (!searchFilter) {
                                                resetSearch();
                                            } else {
                                                setShowSearchInput(false);
                                            }
                                        }}
                                        onKeyDown={(event) => {
                                            if (event.key === 'Escape') {
                                                if (!searchFilter) {
                                                    resetSearch();
                                                } else {
                                                    setShowSearchInput(false);
                                                }
                                            }
                                        }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                backgroundColor:
                                                    'background.paper',
                                                '&.Mui-focused fieldset': {
                                                    borderColor: 'transparent',
                                                },
                                            },
                                        }}
                                    />
                                )}
                            </Box>
                        </Fade>
                    </Box>

                    {/* Create new offer button */}
                    <Button
                        disabled={isLoading}
                        onClick={openNewProjectDialog}
                        sx={{
                            padding: '8px',
                            minWidth: 'auto',
                            fontSize: '1rem',
                        }}
                    >
                        + {t(`views.projectList.createNewOffer`)}
                    </Button>
                </Box>
            </Box>
            <DataGrid
                loading={isLoading}
                onCellClick={(params, event) => {
                    if (params.field === 'offer') {
                        dispatch(
                            setSelectedProject(
                                projectsRawData?.docs?.find(
                                    (project) => project?.id === params.id
                                )
                            )
                        );
                        navigate(appPathConst.project + params.id);
                    }
                }}
                columns={columns}
                rows={projects}
                disableRowSelectionOnClick
                disableColumnSelector
                hideFooter
                sx={{
                    '& *:focus-within': {
                        outline: 'none !important',
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: '#4caf50 !important',
                    },
                    // Hide the last column options on-hover separator
                    '& .data-grid-options-column .MuiDataGrid-iconSeparator': {
                        opacity: 0,
                    },
                    '& .MuiDataGrid-row:hover': {
                        // transparent orange background on hover
                        background: colors.rgba.lightOrange,
                    },
                    border: 'hidden',
                }}
            />
        </>
    );
}

const style = {
    headerBox: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '1rem',
        marginTop: '1rem',
    },
    headerButtons: {
        display: 'flex',
        flexFlow: 'row wrap',
        gap: '2rem',
    },
    searchInputContainer: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
    searchInputVisibleBox: {
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        cursor: 'pointer',
        '&:hover': {
            opacity: 0.7,
        },
    },
    searchInputHiddenBox: {
        position: 'absolute',
        top: '100%',
        right: 0,
        marginTop: '8px',
        zIndex: 1000,
        backgroundColor: 'background.paper',
        borderRadius: 1,
        boxShadow: 3,
        width: '300px',
    },
    headerText: {
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '18px',
        textAlign: 'left',
    },
    optionsHeader: {
        backgroundColor: '#4caf50 !important',
    },
    reminderDateWarning: {
        fontSize: '1rem',
        color: colors.red,
    },
    cellItem: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '100%',
    },
};
