import React, { useState, useEffect, useRef } from 'react';

// mui
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

// ui
import { colors } from 'theming/colors';

// types
import { FiltersConst } from './MaterialPicker';

export interface MaterialPickerFilterProps {
    filterName: string;
    filterArray: PIMFilterGroup[] | PIMFilterCollection[] | PIMFilterFeature[];
    selectedFilterGroups: string[];
    selectedFeatureValues?: string[];
    onCheckBoxClick: (
        item: PIMFilterGroup | PIMFilterCollection | PIMFilterFeature,
        checked: boolean,
        featureValue?: string
    ) => void;
    featureValues?: string[];
    filterType?: FiltersConst;
}

function MaterialPickerFilter(props: MaterialPickerFilterProps) {
    const [expanded, setExpanded] = useState(false);
    const accordionDetailsRef = useRef<HTMLDivElement>(null);

    // check if any items in this filter are selected
    useEffect(() => {
        // for feature values filter (color, format, thickness, etc.)
        if (props.featureValues && props.selectedFeatureValues) {
            const hasSelectedFeatureValue = props.featureValues.some((value) =>
                props.selectedFeatureValues?.includes(value)
            );
            setExpanded(hasSelectedFeatureValue);
        }
        // for regular filters (collections, groups, manufacturers, etc.)
        else if (props.filterArray && props.selectedFilterGroups) {
            const hasSelectedItem = props.filterArray.some((item) =>
                props.selectedFilterGroups.includes(item.id)
            );
            setExpanded(hasSelectedItem);
        }
    }, [
        props.filterArray,
        props.selectedFilterGroups,
        props.featureValues,
        props.selectedFeatureValues,
    ]);

    // scroll to previously selected item (from store) when accordion expands
    useEffect(() => {
        if (expanded && accordionDetailsRef.current) {
            // wait for accordion animation to complete
            setTimeout(() => {
                // find the first checked checkbox element
                const checkedElement =
                    accordionDetailsRef.current?.querySelector(
                        'input[type="checkbox"]:checked'
                    );

                if (checkedElement) {
                    // get the parent FormControlLabel for better positioning
                    const formControlLabel = checkedElement.closest(
                        '.MuiFormControlLabel-root'
                    );
                    if (formControlLabel) {
                        // scroll the element into view
                        formControlLabel.scrollIntoView({
                            behavior: 'smooth',
                            block: 'nearest',
                        });
                    }
                }
            }, 900); // delay to allow accordion animation to complete
        }
    }, [expanded]);

    // handle accordion expansion change
    const handleAccordionChange = (
        event: React.SyntheticEvent,
        isExpanded: boolean
    ) => {
        setExpanded(isExpanded);
    };

    return (
        <Accordion
            sx={style.accordion}
            expanded={expanded}
            onChange={handleAccordionChange}
        >
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                    minHeight: '40px',
                    '& .MuiAccordionSummary-content': {
                        margin: '8px 0',
                    },
                }}
            >
                <Typography
                    sx={{
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        color: colors.black,
                    }}
                >
                    {props.filterName}
                </Typography>
            </AccordionSummary>

            <AccordionDetails
                sx={style.accordionDetails}
                className="vispak-scrollbar"
                ref={accordionDetailsRef}
            >
                {props.featureValues
                    ? props.featureValues.map((value) => (
                          <FormGroup key={value}>
                              <FormControlLabel
                                  control={
                                      <Checkbox
                                          checked={props.selectedFeatureValues?.includes(
                                              value
                                          )}
                                          onChange={(event) =>
                                              props.onCheckBoxClick(
                                                  // if featureValues are provided then
                                                  // filterArray is PIMFilterFeature[]
                                                  // where there is only one feature
                                                  // because the onClicked checkbox can
                                                  // only be grouped under one feature
                                                  props.filterArray[0],
                                                  event.target.checked,
                                                  value
                                              )
                                          }
                                          sx={{
                                              '& .MuiSvgIcon-root': {
                                                  fontSize: 18,
                                                  color: '#000',
                                              },
                                          }}
                                      />
                                  }
                                  label={value}
                              />
                          </FormGroup>
                      ))
                    : props.filterArray.map((category) => (
                          <FormGroup key={category.id}>
                              <FormControlLabel
                                  control={
                                      <Checkbox
                                          checked={props.selectedFilterGroups.includes(
                                              category.id
                                          )}
                                          onChange={(event) =>
                                              props.onCheckBoxClick(
                                                  category,
                                                  event.target.checked
                                              )
                                          }
                                          sx={{
                                              '& .MuiSvgIcon-root': {
                                                  fontSize: 18,
                                                  color: '#000',
                                              },
                                          }}
                                      />
                                  }
                                  label={category.name}
                              />
                          </FormGroup>
                      ))}
            </AccordionDetails>
        </Accordion>
    );
}

// Memoize the component with custom comparison to improve performance
export default React.memo(MaterialPickerFilter, (prevProps, nextProps) => {
    return (
        prevProps.filterName === nextProps.filterName &&
        prevProps.selectedFilterGroups?.length ===
            nextProps.selectedFilterGroups?.length &&
        prevProps.selectedFeatureValues?.length ===
            nextProps.selectedFeatureValues?.length &&
        JSON.stringify(prevProps.filterArray) ===
            JSON.stringify(nextProps.filterArray)
    );
});

const style = {
    accordion: {
        boxShadow: 'none',
        borderRadius: '0px',
        flexDirection: 'row-reverse',
        padding: '0',
        position: 'static',
        '&.MuiPaper-root': {
            boxShadow: 'none',
        },
        '&:before': {
            display: 'none',
        },

        '& .MuiAccordionSummary-expandIconWrapper': {
            transform: 'rotate(-90deg)',
        },
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            color: colors.orange,
            transform: 'rotate(-180deg)',
        },
        '& .MuiAccordionSummary-root': {
            padding: 0,
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: 0,
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            padding: '0',
            margin: '0',
            color: colors.orange,
        },

        fontWeight: 600,
    },
    accordionDetails: {
        padding: 0,
        width: '100%',
        maxHeight: '10rem',
        overflowY: 'auto',
    },
};
