import { createAsyncThunk, createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

import { PIMService } from 'services/PIMService';
import { AxiosError } from 'axios';

export interface PIMState {
    products: PIMProductsResponse;
    filterCollections: PIMFilterCollection[];
    filterGroups: PIMFilterGroup[];
    filterSubgroups: PIMFilterSubGroup[];
    filterManufacturers: PIMFilterManufacturer[];
    filterAllFeatures: PIMFilterFeature[];
    isLoading: boolean;
}

const initialState: PIMState = {
    products: {
        docs: [],
        totalDocs: 0,
        limit: 0,
        totalPages: 0,
        page: 0,
        pagingCounter: 0,
        hasPrevPage: false,
        hasNextPage: false,
        prevPage: null,
        nextPage: null,
    },
    filterCollections: [],
    filterGroups: [],
    filterSubgroups: [],
    filterManufacturers: [],
    filterAllFeatures: [],
    isLoading: false,
};

export const fetchFilterManufacturers = createAsyncThunk<
    FilterManufacturersResponse, // Return type of the payload
    { limit: number }, // arguments
    { rejectValue: string } // Error type
>('pim/fetchFilterManufacturers', async ({ limit }, { rejectWithValue }) => {
    try {
        const pimService = new PIMService();
        const response = await pimService.getFilterManufacturers({ limit });
        return response;
    } catch (err) {
        const error = err as AxiosError;
        return rejectWithValue(error?.message);
    }
});

export const fetchFilterAllFeatures = createAsyncThunk<
    FilterFeaturesResponse, // Return type of the payload
    { limit: number }, // arguments
    { rejectValue: string } // Error type
>('pim/fetchFilterAllFeatures', async ({ limit }, { rejectWithValue }) => {
    try {
        const pimService = new PIMService();
        const response = await pimService.getFilterAllFeatures({ limit });
        return response;
    } catch (err) {
        const error = err as AxiosError;
        return rejectWithValue(error?.message);
    }
});

// PIM subgroups (UI sub-categories)
export const fetchFilterSubGroups = createAsyncThunk<
    FilterSubgroupsResponse, // Return type of the payload
    { limit: number }, // arguments
    { rejectValue: string } // Error type
>('pim/fetchFilterSubgroups', async ({ limit }, { rejectWithValue }) => {
    try {
        const pimService = new PIMService();
        const response = await pimService.getFilterSubgroups({ limit });
        return response;
    } catch (err) {
        const error = err as AxiosError;
        return rejectWithValue(error?.message);
    }
});

// PIM groups (UI categories)
export const fetchFilterGroups = createAsyncThunk<
    FilterGroupsResponse, // Return type of the payload
    { limit: number }, // arguments
    { rejectValue: string } // Error type
>('pim/fetchFilterGroups', async ({ limit }, { rejectWithValue }) => {
    try {
        const pimService = new PIMService();
        const response = await pimService.getFilterGroups({ limit });
        return response;
    } catch (err) {
        const error = err as AxiosError;
        return rejectWithValue(error?.message);
    }
});

// PIM categories (UI collections)
export const fetchFilterCollections = createAsyncThunk<
    FilterCollectionsResponse, // Return type of the payload
    { limit: number; depth: number }, // arguments
    { rejectValue: string } // Error type
>(
    'pim/fetchFilterCollections',
    async ({ limit, depth }, { rejectWithValue }) => {
        try {
            const pimService = new PIMService();
            const response = await pimService.getFilterCollections({
                limit,
                depth,
            });
            return response;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error?.message);
        }
    }
);

export const fetchProducts = createAsyncThunk<
    PIMProductsResponse, // Return type of the payload
    { limit: number; page?: number; depth?: number }, // arguments
    { rejectValue: string } // Error type
>(
    'pim/fetchProducts',
    async ({ limit, page = 1, depth = 1 }, { rejectWithValue }) => {
        try {
            const pimService = new PIMService();
            const response = await pimService.getAllProducts({
                limit,
                page,
                depth,
            });
            return response;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error?.message);
        }
    }
);

export const fetchProductsBySearchTerm = createAsyncThunk<
    PIMProductsResponse, // Return type of the payload
    {
        limit: number;
        page?: number;
        depth?: number;
        searchTerm?: string;
        collections?: string[];
        groups?: string[];
        subGroups?: string[];
        manufacturers?: string[];
        features?: string[];
    }, // arguments
    { rejectValue: string } // Error type
>('pim/fetchProductsBySearchTerm', async (params, { rejectWithValue }) => {
    try {
        const pimService = new PIMService();
        const response = await pimService.getAllProductsBySearchTerm(params);
        return response;
    } catch (err) {
        const error = err as AxiosError;
        return rejectWithValue(error?.message);
    }
});

const pimSlice = createSlice({
    name: 'pim',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchProducts.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchProducts.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(
                fetchProducts.fulfilled,
                (state, action: PayloadAction<PIMProductsResponse>) => {
                    state.products = action.payload;
                    state.isLoading = false;
                }
            )
            .addCase(fetchProductsBySearchTerm.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchProductsBySearchTerm.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(
                fetchProductsBySearchTerm.fulfilled,
                (state, action: PayloadAction<PIMProductsResponse>) => {
                    state.products = action.payload;
                    state.isLoading = false;
                }
            )
            .addCase(fetchFilterCollections.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchFilterCollections.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(
                fetchFilterCollections.fulfilled,
                (state, action: PayloadAction<FilterCollectionsResponse>) => {
                    state.filterCollections = action.payload.docs;
                    state.isLoading = false;
                }
            )
            .addCase(fetchFilterGroups.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchFilterGroups.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(
                fetchFilterGroups.fulfilled,
                (state, action: PayloadAction<FilterGroupsResponse>) => {
                    state.filterGroups = action.payload.docs;
                    state.isLoading = false;
                }
            )
            .addCase(fetchFilterSubGroups.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchFilterSubGroups.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(
                fetchFilterSubGroups.fulfilled,
                (state, action: PayloadAction<FilterSubgroupsResponse>) => {
                    state.filterSubgroups = action.payload.docs;
                    state.isLoading = false;
                }
            )
            .addCase(fetchFilterManufacturers.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchFilterManufacturers.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(
                fetchFilterManufacturers.fulfilled,
                (state, action: PayloadAction<FilterManufacturersResponse>) => {
                    state.filterManufacturers = action.payload.docs;
                    state.isLoading = false;
                }
            )
            .addCase(fetchFilterAllFeatures.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchFilterAllFeatures.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(
                fetchFilterAllFeatures.fulfilled,
                (state, action: PayloadAction<FilterFeaturesResponse>) => {
                    state.filterAllFeatures = action.payload.docs;
                    state.isLoading = false;
                }
            );
    },
});

export const {} = pimSlice.actions;

// Selectors
export const selectPIMProducts = (state: RootState) => state.pim.products;
export const selectPIMFilterCollections = (state: RootState) =>
    state.pim.filterCollections;
export const selectPIMFilterGroups = (state: RootState) =>
    state.pim.filterGroups;
export const selectPIMFilterSubGroups = (state: RootState) =>
    state.pim.filterSubgroups;
export const selectPIMFilterManufacturers = (state: RootState) =>
    state.pim.filterManufacturers;
export const selectPIMFilterAllFeatures = (state: RootState) =>
    state.pim.filterAllFeatures;
export const selectPIMIsLoading = (state: RootState) => state.pim.isLoading;

// memoized selectors
export const selectMemoizedPIMProducts = createSelector(
    [selectPIMProducts],
    (products) => products
);

export default pimSlice.reducer;
