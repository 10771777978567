import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import { SxProps } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import TooltipStyled from './TooltipStyled';
import { useTranslation } from 'react-i18next';

export interface CommentButtonProps {
    comments: UserComment[];
    sx: SxProps;
    onClick: () => void;
}

const CommentButton = (props: CommentButtonProps) => {
    const { t: translate } = useTranslation();

    const handleClick = () => {
        props.onClick();
    };

    const hasComments = props.comments?.length > 0;

    return (
        <TooltipStyled
            title={
                hasComments
                    ? translate('views.project.notes')
                    : translate('views.project.noNotes')
            }
            placement="top-start"
        >
            <IconButton
                color={hasComments ? 'primary' : 'secondary'}
                sx={{ ...style.button, ...props.sx }}
                onClick={handleClick}
            >
                {hasComments ? (
                    <CommentOutlinedIcon />
                ) : (
                    <ModeCommentOutlinedIcon />
                )}
            </IconButton>
        </TooltipStyled>
    );
};

export default CommentButton;

const style: any = {
    button: {},
};
