import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// mui
import {
    Box,
    IconButton,
    Stack,
    Typography,
    CircularProgress,
} from '@mui/material';
import {
    AddOutlined,
    ExitToAppOutlined,
    PhotoCameraOutlined,
} from '@mui/icons-material';

// ui
import Button from 'components/base/Button';
import BreadcrumbNavigation from 'components/BreadcrumbNavigation';
import SnapshotSelect from './SnapshotSelect';

// store
import {
    selectSpacialSolutions,
    selectDesignCodeSpacialSolutions,
    selectProjects,
    selectCompareWithVariation,
    setCompareWithVariation,
    selectDesignCodeProject,
    selectSelectedProject,
} from 'features/projects/state/projectState';

// Services
import { PIMService } from 'services/PIMService';

// helpers
import {
    getSizeFromHalfWidth,
    getElementDistanceFromLeft,
    getElementDistanceFromTop,
    generateHashFromTimestamp,
    generateCurrentTimestampString,
    useRedirectIfNoId,
} from 'utils';

// config
import { colors } from 'theming/colors';
import { PLACEHOLDER_IMAGE_URL, COMPARE_VIEW_BLANK_IMAGE_URL } from 'config';

// types

export interface ComparisonViewVariationSnapshot {
    snapshotURL: string;
    thumbnailURL: string;
    variation: Variation;
    room: Room;
}

const ComparisonView = () => {
    const contentBoxRef = useRef<HTMLElement | null>(null);
    const rightSideBoxRef = useRef<HTMLImageElement | null>(null);
    const leftSideBoxRef = useRef<HTMLImageElement | null>(null);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // local state

    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    // only snapshotURL from ComparisonViewVariationSnapshot is needed atm.
    const [selectedSnapshotLeft, setSelectedSnapshotLeft] =
        useState<Partial<ComparisonViewVariationSnapshot> | null>(null);
    const [selectedSnapshotRight, setSelectedSnapshotRight] =
        useState<Partial<ComparisonViewVariationSnapshot> | null>(null);

    // positioning
    const [leftPosition, setLeftPosition] = useState<number>(0);
    const [rightPosition, setRightPosition] = useState<number>(0);
    const [distanceFromTop, setDistanceFromTop] = useState<number>(0);

    // Single state to track which snapshot button is open ('left', 'right', or null)
    const [openSnapshot, setOpenSnapshot] = useState<'left' | 'right' | null>(
        null
    );

    const [isCapturingScreenshot, setIsCapturingScreenshot] = useState(false);
    const [isLeftImageLoading, setIsLeftImageLoading] = useState(false);
    const [isRightImageLoading, setIsRightImageLoading] = useState(false);

    const [processedLeftSnapshotUrl, setProcessedLeftSnapshotUrl] =
        useState<string>('');
    const [processedRightSnapshotUrl, setProcessedRightSnapshotUrl] =
        useState<string>('');

    // properties

    // if set it's a design code session (store is populated with designCodeProject)
    const designCodeProject = useSelector(selectDesignCodeProject);

    const selectedProjectFromRegularSession = useSelector(
        selectSelectedProject
    );

    const baseUrl = window.location.origin;

    const isLoading =
        isCapturingScreenshot || isLeftImageLoading || isRightImageLoading;

    const spacialSolutionsFromUserSession = useSelector(selectSpacialSolutions);
    const spacialSolutionsFromDesignCode = useSelector(
        selectDesignCodeSpacialSolutions
    );

    const spacialSolutions = !designCodeProject
        ? spacialSolutionsFromUserSession
        : spacialSolutionsFromDesignCode;

    const projects = useSelector(selectProjects);

    const redirectIfNoId = useRedirectIfNoId(projects?.docs || []);

    const compareWithVariationId = useSelector(selectCompareWithVariation);

    // Selected project
    const selectedProject = !designCodeProject
        ? selectedProjectFromRegularSession
        : designCodeProject;

    const headerHeight = 70; // #comparison-view-header height
    const verticalPadding = 10; // Total vertical padding
    const imageHeight = dimensions.height - headerHeight - verticalPadding;
    const imageWidth = dimensions.width / 2 - 20;

    // features

    const processSnapshotUrl = useCallback(
        async (snapshotUrl: string | undefined) => {
            if (!snapshotUrl) {
                return '';
            }

            if (designCodeProject) {
                const designCodeParameter = `?designcode=${designCodeProject.designCode}`;
                return snapshotUrl + designCodeParameter;
            } else {
                const pimService = new PIMService();
                const image = await pimService.getImage(snapshotUrl);
                if (image) {
                    return URL.createObjectURL(image);
                }
            }
            return snapshotUrl;
        },
        [designCodeProject]
    );

    // Add image loading handlers
    const handleLeftImageLoad = () => {
        setIsLeftImageLoading(false);
    };

    const handleRightImageLoad = () => {
        setIsRightImageLoading(false);
    };

    const onTakeScreenshot = () => {
        setOpenSnapshot(null);
        const element = document.getElementById('comparison-view-content');
        if (!element) return;

        setIsCapturingScreenshot(true);

        // Wait for all images to load
        const images = element.getElementsByTagName('img');
        const imageLoadPromises = Array.from(images).map((img) => {
            if (img.complete) return Promise.resolve();
            return new Promise((resolve) => {
                img.onload = resolve;
                img.onerror = resolve;
            });
        });

        Promise.all(imageLoadPromises).then(() => {
            import('html2canvas').then((html2canvas) => {
                html2canvas
                    .default(element, {
                        useCORS: true,
                        allowTaint: true,
                        logging: false,
                        imageTimeout: 0,
                    })
                    .then((canvas) => {
                        const screenshot = canvas.toDataURL('image/png');
                        const link = document.createElement('a');
                        const currentDate = generateCurrentTimestampString();
                        link.href = screenshot;
                        link.download =
                            'võrdlusvaade_' +
                            selectedProject?.name +
                            '_' +
                            currentDate +
                            '.png';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    })
                    .finally(() => {
                        setIsCapturingScreenshot(false);
                    });
            });
        });
    };

    const onLeftSnapshotClick = (snapshot: ComparisonViewVariationSnapshot) => {
        setOpenSnapshot(null);
        setSelectedSnapshotLeft(snapshot);
    };

    const onRightSnapshotClick = (
        snapshot: ComparisonViewVariationSnapshot
    ) => {
        setOpenSnapshot(null);
        setSelectedSnapshotRight(snapshot);
    };

    const calculateDistanceFromTop = () => {
        if (contentBoxRef.current) {
            const distance = getElementDistanceFromTop(contentBoxRef);
            setDistanceFromTop(distance);
        }
    };

    const handleResize = () => {
        setDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
        });
        calculateDistanceFromTop();

        if (rightSideBoxRef.current && leftSideBoxRef.current) {
            const distanceToRight = getElementDistanceFromLeft(rightSideBoxRef);
            setRightPosition(distanceToRight);
            setLeftPosition(getSizeFromHalfWidth(window.innerWidth, 0.05));
        } else {
            // Fallback
            setRightPosition(getSizeFromHalfWidth(window.innerWidth, 1.05));
            setLeftPosition(getSizeFromHalfWidth(window.innerWidth, 0.05));
        }
    };

    const handleLeftSnapshotButtonClick = () => {
        setOpenSnapshot((prev) => (prev === 'left' ? null : 'left'));
    };

    const handleRightSnapshotButtonClick = () => {
        setOpenSnapshot((prev) => (prev === 'right' ? null : 'right'));
    };

    // side effects

    // auth check
    useEffect(() => {
        redirectIfNoId();
    }, [redirectIfNoId]);

    useEffect(() => {
        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // track image URL changes
    useEffect(() => {
        if (selectedSnapshotLeft?.snapshotURL) {
            setIsLeftImageLoading(true);
            processSnapshotUrl(selectedSnapshotLeft.snapshotURL).then((url) => {
                setProcessedLeftSnapshotUrl(url);
            });
        }
    }, [selectedSnapshotLeft?.snapshotURL, processSnapshotUrl]);

    useEffect(() => {
        if (selectedSnapshotRight?.snapshotURL) {
            setIsRightImageLoading(true);
            processSnapshotUrl(selectedSnapshotRight.snapshotURL).then(
                (url) => {
                    setProcessedRightSnapshotUrl(url);
                }
            );
        }
    }, [selectedSnapshotRight?.snapshotURL, processSnapshotUrl]);

    // if compareWithVariationId is set then need to load appropriate snapshot
    // as this id was set in other view (moodboard, project view)
    useEffect(() => {
        if (compareWithVariationId && spacialSolutions.length) {
            let foundRoom: Room | null = null;
            const foundVariation = spacialSolutions
                .flatMap((solution) =>
                    solution.rooms?.flatMap((room) =>
                        room.variations?.find((variation) => {
                            if (variation.id === compareWithVariationId) {
                                foundRoom = room;
                                return true;
                            }
                            return false;
                        })
                    )
                )
                // filter out undefined variations
                .filter(
                    (variation) => variation?.id === compareWithVariationId
                ) as Variation[];

            if (foundVariation.length > 0) {
                const variation = foundVariation[0];
                const recentSnapshot =
                    variation.snapshots?.[variation.snapshots?.length - 1];

                const snapshot: Partial<ComparisonViewVariationSnapshot> = {
                    snapshotURL: recentSnapshot?.url || '',
                    thumbnailURL:
                        recentSnapshot?.sizes?.thumbnail?.url ||
                        recentSnapshot?.sizes?.medium?.url ||
                        '',
                    variation,
                    ...(foundRoom ? { room: foundRoom } : {}),
                };

                setSelectedSnapshotLeft(snapshot);
            }
        }

        // reset comparable variation in store
        return () => {
            dispatch(setCompareWithVariation(null));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [compareWithVariationId]);

    return (
        <>
            <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                padding={'0.875rem'}
                id="comparison-view-header"
            >
                <BreadcrumbNavigation />
                <Stack direction={'row'} alignItems={'center'} gap={2}>
                    {isCapturingScreenshot && <CircularProgress size={26} />}
                    <IconButton
                        onClick={onTakeScreenshot}
                        sx={getIconButtonStyle(isCapturingScreenshot)}
                        disabled={isLoading}
                    >
                        <PhotoCameraOutlined />
                        <Typography sx={styles.headerButtonText}>
                            {t(`views.configurator.screenshot`)}
                        </Typography>
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            navigate(-1);
                        }}
                        sx={getIconButtonStyle(isCapturingScreenshot)}
                        disabled={isLoading}
                    >
                        <ExitToAppOutlined />
                        <Typography sx={styles.headerButtonText}>
                            {t(`views.comparison.leave`)}
                        </Typography>
                    </IconButton>
                </Stack>
            </Stack>

            <Box
                component="div"
                sx={{
                    padding: 0,
                    backgroundColor: colors.white,
                    position: 'relative',
                    height: `calc(100vh - ${headerHeight}px)`,
                    display: 'flex',
                    alignItems: 'center',
                }}
                ref={contentBoxRef}
                id="comparison-view-content"
            >
                <Stack spacing={0} sx={{ width: '100%' }}>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        spacing={1}
                        id="comparison-view-images"
                    >
                        <Box component="div" sx={{ position: 'relative' }}>
                            <Box
                                id="leftSideImageBox"
                                component="img"
                                src={
                                    processedLeftSnapshotUrl ||
                                    `${baseUrl}${COMPARE_VIEW_BLANK_IMAGE_URL}`
                                }
                                alt="placeholder"
                                ref={leftSideBoxRef}
                                crossOrigin="anonymous"
                                onLoad={handleLeftImageLoad}
                                onError={(
                                    e: React.SyntheticEvent<
                                        HTMLImageElement,
                                        Event
                                    >
                                ) => {
                                    handleLeftImageLoad();
                                    e.currentTarget.onerror = null;
                                    e.currentTarget.src = `${baseUrl}${PLACEHOLDER_IMAGE_URL}`;
                                }}
                                sx={{
                                    width: imageWidth,
                                    height: imageHeight, // Fixed height
                                    objectFit: 'cover', // Maintain aspect ratio
                                    alignSelf: 'center',
                                }}
                            />
                            {/* Updated overlay for left image */}
                            {selectedSnapshotLeft?.room?.name && (
                                <Box
                                    component="div"
                                    sx={{
                                        ...styles.imageOverlayBar,
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        sx={styles.overlayText}
                                    >
                                        {`${selectedSnapshotLeft?.room?.name} [ ${selectedSnapshotLeft?.variation?.name} ]`}
                                    </Typography>
                                </Box>
                            )}
                            {isLeftImageLoading && (
                                <Box
                                    component="div"
                                    sx={styles.imageLoadingOverlay}
                                >
                                    <CircularProgress />
                                </Box>
                            )}
                        </Box>

                        <Box component="div" sx={{ position: 'relative' }}>
                            <Box
                                id="rightSideImageBox"
                                component="img"
                                src={
                                    processedRightSnapshotUrl ||
                                    `${baseUrl}${COMPARE_VIEW_BLANK_IMAGE_URL}`
                                }
                                alt="placeholder2"
                                ref={rightSideBoxRef}
                                crossOrigin="anonymous"
                                onLoad={handleRightImageLoad}
                                onError={(
                                    e: React.SyntheticEvent<
                                        HTMLImageElement,
                                        Event
                                    >
                                ) => {
                                    handleRightImageLoad();
                                    e.currentTarget.onerror = null;
                                    e.currentTarget.src = `${baseUrl}${PLACEHOLDER_IMAGE_URL}`;
                                }}
                                sx={{
                                    width: imageWidth,
                                    height: imageHeight, // Fixed height
                                    objectFit: 'cover', // Maintain aspect ratio
                                    alignSelf: 'center',
                                }}
                            />
                            {/* Updated overlay for right image */}
                            {selectedSnapshotRight?.room?.name && (
                                <Box
                                    component="div"
                                    sx={{
                                        ...styles.imageOverlayBar,
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        sx={styles.overlayText}
                                    >
                                        {`${selectedSnapshotRight?.room?.name} [ ${selectedSnapshotRight?.variation?.name} ]`}
                                    </Typography>
                                </Box>
                            )}
                            {isRightImageLoading && (
                                <Box
                                    component="div"
                                    sx={styles.imageLoadingOverlay}
                                >
                                    <CircularProgress />
                                </Box>
                            )}
                        </Box>
                    </Stack>
                </Stack>
            </Box>

            <Button
                id="leftSnapshotButton"
                onClick={handleLeftSnapshotButtonClick}
                sx={{
                    position: 'absolute',
                    left: `${leftPosition}px`,
                    top: distanceFromTop
                        ? `${distanceFromTop + 32}px`
                        : '12rem',
                }}
                disabled={isLoading}
            >
                <AddOutlined />
            </Button>
            <Button
                id="rightSnapshotButton"
                onClick={handleRightSnapshotButtonClick}
                sx={{
                    position: 'absolute',
                    left: `${rightPosition + 16}px`,
                    top: distanceFromTop
                        ? `${distanceFromTop + 32}px`
                        : '12rem',
                }}
                disabled={isLoading}
            >
                <AddOutlined />
            </Button>

            {/* Modals */}

            {openSnapshot === 'left' && (
                <Box
                    component="div"
                    style={{
                        position: 'absolute',
                        top: distanceFromTop
                            ? `${distanceFromTop + 92}px`
                            : '12rem',
                        left: `${leftPosition}px`,
                        zIndex: 2,
                    }}
                >
                    <SnapshotSelect
                        spacialSolutions={spacialSolutions}
                        onSnapshotClick={onLeftSnapshotClick}
                    />
                </Box>
            )}

            {openSnapshot === 'right' && (
                <Box
                    component="div"
                    style={{
                        position: 'absolute',
                        top: distanceFromTop
                            ? `${distanceFromTop + 92}px`
                            : '12rem',
                        left: `${rightPosition + 16}px`,
                        zIndex: 2,
                    }}
                >
                    <SnapshotSelect
                        spacialSolutions={spacialSolutions}
                        onSnapshotClick={onRightSnapshotClick}
                    />
                </Box>
            )}
        </>
    );
};

export default ComparisonView;

const styles = {
    comparisonHeader: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginRight: '1rem',
        minHeight: '4rem',
    },
    headerButtonText: {
        marginLeft: '0.5rem',
        fontSize: '1rem',
        color: colors.black,
    },
    imageLoadingOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
    },
    imageOverlayBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        position: 'absolute',
        top: '2rem',
        height: 'auto',
        width: '50%',
        left: '30%',

        padding: '8px',
        background: 'rgba(255, 255, 255, 0.5)',
        backdropFilter: 'blur(4px)',
    },
    overlayText: {
        textAlign: 'center',
        color: colors.black,
        fontWeight: 500,
    },
};

const getIconButtonStyle = (isDisabled: boolean) => ({
    opacity: isDisabled ? 0.2 : 1,
    color: colors.black,
    '&:hover': {
        backgroundColor: 'transparent',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
    },
    pointerEvents: isDisabled ? 'none' : 'auto',
});
