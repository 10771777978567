import { useRef } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';

const DirectionalLights: React.FC = () => {
    const { camera } = useThree();
    const shadowMapSize = 8000;
    const lightIntensity = 0.1;
    const shadowIntensity = 10
    const shadowCameraSideLengthLR = 13; //Left/right side length
    const shadowCameraSideLengthTB = 18; //Top/bottom side length
    const lightOffsetX = 1.5;
    const lightOffsetZ = 1;
    const lightOffset = new THREE.Vector3(lightOffsetX, 0, lightOffsetZ);

    // Light and target refs
    const lightRef = useRef<THREE.DirectionalLight>(null);
    const targetRef = useRef<THREE.Object3D>(new THREE.Object3D());

    const { scene } = useThree();

    // Debugging helpers
    // useEffect(() => {
    //     if (lightRef.current) {
    //         const helper = new THREE.CameraHelper(
    //             lightRef.current.shadow.camera
    //         );
    //         scene.add(helper);
    //         return () => {
    //             scene.remove(helper);
    //             helper.dispose(); // Clean up memory
    //         };
    //     }
    // }, [scene]);

    // useHelper(
    //     //@ts-ignore
    //     lightRef,
    //     THREE.DirectionalLightHelper,
    //     5,
    //     new THREE.Color('red')
    // );

    useFrame(() => {
        if (lightRef.current && targetRef.current) {
            // Position the light relative to the camera
            lightRef.current.position.copy(camera.position).add(lightOffset);

            lightRef.current.position.y = 10;

            // Target should be in the center of the camera
            targetRef.current.position.copy(lightRef.current.position);
            targetRef.current.position.y = 0; //Set target on the background plane
            targetRef.current.position.z += -0.6; // Offset target from light for better shadows
            targetRef.current.position.x += -0.9; // Offset target from light for better shadows

            // Update Three.js transformations
            targetRef.current.updateMatrixWorld();
        }
    });

    return (
        <>
            <directionalLight
                ref={lightRef}
                intensity={lightIntensity}
                castShadow
                shadow-mapSize-width={shadowMapSize}
                shadow-mapSize-height={shadowMapSize}
                shadow-camera-far={100}
                shadow-camera-near={1}
                shadow-camera-left={-shadowCameraSideLengthLR}
                shadow-camera-right={shadowCameraSideLengthLR}
                shadow-camera-top={shadowCameraSideLengthTB}
                shadow-camera-bottom={-shadowCameraSideLengthTB}
                shadow-bias={-0.0005}
                shadow-intensity={shadowIntensity}
                target={targetRef.current}
            />
            <primitive object={targetRef.current} />
        </>
    );
};

export default DirectionalLights;
