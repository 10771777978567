import Root from 'views/Root';
import { createBrowserRouter, Navigate } from 'react-router-dom';

import LoginView from 'features/authentication/views/LoginView';
import EnterWithDesignCodeView from 'features/authentication/views/EnterWithDesignCodeView';
import ProjectListView from 'features/projects/views/ProjectListView';
import ProjectView from 'features/projects/views/ProjectView';
import RoomView from 'features/configurator/RoomView';
import ComparisonView from 'features/comparison/ComparisonView';
import ProductListView from 'features/productList/ProductListView';
import ForgotPasswordView from '../features/authentication/views/ForgotPasswordView';
import ResetPasswordView from '../features/authentication/views/ResetPasswordView';

export interface AppPath {
    root: string;
    login: string;
    designCode: string;
    forgotPassword: string;
    resetPassword: string;
    projects: string;
    project: string;
    room: string;
    comparison: string;
    productList: string;
}

export const appPath: AppPath = {
    root: '/',
    login: '/login',
    designCode: '/login/code',
    forgotPassword: '/login/forgot-password',
    resetPassword: '/login/reset-password',
    projects: '/projects',
    project: '/project/:projectId',
    room: '/room/:roomId',
    comparison: '/comparison',
    productList: '/productList',
};

export const appPathConst = {
    project: '/project/',
    room: '/room/',
}

const router = createBrowserRouter([
    {
        path: appPath.root,
        element: <Root />,
        children: [
            {
                index: true,
                element: <Navigate to={appPath.login} replace />,
            },
            {
                path: appPath.login,
                element: <LoginView />,
            },
            {
                path: appPath.designCode,
                element: <EnterWithDesignCodeView />,
            },
            {
                path: appPath.forgotPassword,
                element: <ForgotPasswordView />,
            },
            {
                path: appPath.resetPassword,
                element: <ResetPasswordView />,
            },
            {
                path: appPath.projects,
                element: <ProjectListView />,
            },
            {
                path: appPath.project,
                element: <ProjectView />,
            },
            {
                path: appPath.room,
                element: <RoomView />,
            },
            {
                path: appPath.comparison,
                element: <ComparisonView />,
            },
            {
                path: appPath.productList,
                element: <ProductListView />,
            },
        ],
    },
]);

export default router;
