import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { colors } from 'theming/colors';
import LazyLoad from 'react-lazy-load';
import { PLACEHOLDER_IMAGE_URL } from 'config';
import { useTranslation } from 'react-i18next';

// mui
import { Typography, Box, CircularProgress } from '@mui/material';

// store
import { selectDesignCodeProject } from 'features/projects/state/projectState';

// services
import { PIMService } from 'services/PIMService';

// types
import { ProductFeatureName } from 'features/productList/ProductListView';

// image cache configuration
const MAX_CACHE_SIZE = 2000; // maximum number of images to cache
const imageCache = new Map<string, string>();
const objectUrlsToRevoke = new Set<string>();
const cacheKeys: string[] = []; // to track order for LRU eviction

// custom hook for cached images
const useCachedImage = (productImageUrl: string, designCodeProject: any) => {
    const [fetchedProductImageUrl, setFetchedProductImageUrl] = useState('');
    const [imageLoading, setImageLoading] = useState(true);
    
    const cacheKey = designCodeProject 
        ? `${productImageUrl}?designcode=${designCodeProject.designCode}`
        : productImageUrl;
        
    useEffect(() => {
        const fetchImage = async () => {
            if (!productImageUrl) {
                setImageLoading(false);
                return;
            }
            
            // check if image is already in cache
            if (imageCache.has(cacheKey)) {
                // move this key to the end of the array (most recently used)
                const keyIndex = cacheKeys.indexOf(cacheKey);
                if (keyIndex > -1) {
                    cacheKeys.splice(keyIndex, 1);
                }
                cacheKeys.push(cacheKey);
                
                setFetchedProductImageUrl(imageCache.get(cacheKey) || '');
                setImageLoading(false);
                return;
            }
            
            // evict oldest item if cache is full
            if (imageCache.size >= MAX_CACHE_SIZE && cacheKeys.length > 0) {
                const oldestKey = cacheKeys.shift();
                if (oldestKey) {
                    const oldUrl = imageCache.get(oldestKey);
                    if (oldUrl && oldUrl.startsWith('blob:')) {
                        URL.revokeObjectURL(oldUrl);
                        objectUrlsToRevoke.delete(oldUrl);
                    }
                    imageCache.delete(oldestKey);
                }
            }
            
            // fetch and cache the image
            try {
                if (designCodeProject) {
                    const designCodeParameter = `?designcode=${designCodeProject.designCode}`;
                    const url = productImageUrl + designCodeParameter;
                    setFetchedProductImageUrl(url);
                    imageCache.set(cacheKey, url);
                    cacheKeys.push(cacheKey);
                } else {
                    const pimService = new PIMService();
                    const image = await pimService.getImage(productImageUrl);
                    if (image) {
                        const objectUrl = URL.createObjectURL(image);
                        setFetchedProductImageUrl(objectUrl);
                        imageCache.set(cacheKey, objectUrl);
                        objectUrlsToRevoke.add(objectUrl);
                        cacheKeys.push(cacheKey);
                    }
                }
            } catch (error) {
                console.error('error fetching image:', error);
            }
        };
        
        fetchImage();
    }, [cacheKey, productImageUrl, designCodeProject]);
    
    // cleanup object urls when component unmounts
    useEffect(() => {
        return () => {
            // we don't revoke URLs here to keep them in cache
            // they will be revoked when the app unmounts or when cache is cleared
        };
    }, []);
    
    return { fetchedProductImageUrl, imageLoading, setImageLoading };
};

// function to clear cache and revoke object urls
// can be exported and called when needed (e.g., when user logs out or app unmounts)
export const clearImageCache = () => {
    // revoke all object urls to prevent memory leaks
    objectUrlsToRevoke.forEach(url => {
        if (url.startsWith('blob:')) {
            URL.revokeObjectURL(url);
        }
    });
    
    // clear the cache and object urls set
    imageCache.clear();
    objectUrlsToRevoke.clear();
    cacheKeys.length = 0;
};

// add event listener to clear cache when window unloads
if (typeof window !== 'undefined') {
    window.addEventListener('beforeunload', clearImageCache);
}

// function to preload product images
export const preloadProductImages = (products: PIMProduct[], designCodeProject: any) => {
    if (!products || products.length === 0) return;
    
    // process up to 10 products at a time to avoid overwhelming the browser
    const batchSize = 10;
    const productsToProcess = products.slice(0, 30); // limit to first 30 products
    
    // process in batches
    for (let i = 0; i < productsToProcess.length; i += batchSize) {
        const batch = productsToProcess.slice(i, i + batchSize);
        
        // use setTimeout to avoid blocking the main thread
        setTimeout(() => {
            batch.forEach(product => {
                // get the image url using the same logic as in the component
                const productImageUrl =
                    product?.designImageSlider?.[0]?.designImage?.sizes?.thumbnail?.url ||
                    product?.designImageSlider?.[0]?.designImage?.sizes?.medium?.url ||
                    product?.designImageSlider?.[0]?.designImage?.url ||
                    product?.interiorImageSlider?.[0]?.interiorImage?.sizes?.thumbnail?.url ||
                    product?.interiorImageSlider?.[0]?.interiorImage?.sizes?.medium?.url ||
                    product?.interiorImageSlider?.[0]?.interiorImage?.url ||
                    product?.referenceAlbumSlider?.[0]?.referenceAlbums?.sizes?.thumbnail?.url ||
                    product?.referenceAlbumSlider?.[0]?.referenceAlbums?.sizes?.medium?.url ||
                    product?.referenceAlbumSlider?.[0]?.referenceAlbums?.url ||
                    '';
                
                if (!productImageUrl) return;
                
                const cacheKey = designCodeProject 
                    ? `${productImageUrl}?designcode=${designCodeProject.designCode}`
                    : productImageUrl;
                
                // if already in cache, skip
                if (imageCache.has(cacheKey)) return;
                
                // fetch and cache the image
                if (designCodeProject) {
                    const designCodeParameter = `?designcode=${designCodeProject.designCode}`;
                    const url = productImageUrl + designCodeParameter;
                    imageCache.set(cacheKey, url);
                    cacheKeys.push(cacheKey);
                } else {
                    const pimService = new PIMService();
                    pimService.getImage(productImageUrl).then(image => {
                        if (image) {
                            const objectUrl = URL.createObjectURL(image);
                            imageCache.set(cacheKey, objectUrl);
                            objectUrlsToRevoke.add(objectUrl);
                            cacheKeys.push(cacheKey);
                        }
                    }).catch(error => {
                        console.error('error preloading image:', error);
                    });
                }
            });
        }, 0);
    }
};

export interface ProductImageProps {
    product: PIMProduct;
    onProductClick: (product: PIMProduct) => void;
}

function ProductImage(props: ProductImageProps) {
    const { t } = useTranslation();

    // properties
    const designCodeProject = useSelector(selectDesignCodeProject);
    const { product, onProductClick } = props;
    const baseUrl = window.location.origin;
    
    const productFormat = product?.featuresArray?.find(
        (item: PIMProductFeature) =>
            item?.featureName
                ?.toLowerCase()
                ?.includes(ProductFeatureName.PLATE_FORMAT)
    );
    const plateThickness = product?.featuresArray?.find(
        (item: PIMProductFeature) =>
            item?.featureName?.toLowerCase() ===
            ProductFeatureName.THICKNESS.toLowerCase()
    );

    // Collection name in UI
    const productCategory = product?.category?.value?.name;

    // go through all image groups and get the first one that exists
    const productImageUrl =
        product?.designImageSlider?.[0]?.designImage?.sizes?.thumbnail?.url ||
        product?.designImageSlider?.[0]?.designImage?.sizes?.medium?.url ||
        product?.designImageSlider?.[0]?.designImage?.url ||
        product?.interiorImageSlider?.[0]?.interiorImage?.sizes?.thumbnail
            ?.url ||
        product?.interiorImageSlider?.[0]?.interiorImage?.sizes?.medium?.url ||
        product?.interiorImageSlider?.[0]?.interiorImage?.url ||
        product?.referenceAlbumSlider?.[0]?.referenceAlbums?.sizes?.thumbnail
            ?.url ||
        product?.referenceAlbumSlider?.[0]?.referenceAlbums?.sizes?.medium
            ?.url ||
        product?.referenceAlbumSlider?.[0]?.referenceAlbums?.url ||
        '';

    // use the cached image hook
    const { fetchedProductImageUrl, imageLoading, setImageLoading } = useCachedImage(
        productImageUrl,
        designCodeProject
    );

    return (
        <Box
            key={product.id}
            component={'div'}
            sx={style.productItemContainer}
            onClick={() => onProductClick(product)}
        >
            <Box component={'div'} sx={style.productItem}>
                <Box component={'div'} sx={style.productImage}>
                    {imageLoading && (
                        <Box component={'div'} sx={style.loadingContainer}>
                            <CircularProgress size={24} />
                        </Box>
                    )}
                    <LazyLoad>
                        <img
                            src={fetchedProductImageUrl}
                            alt={product.name}
                            onLoad={() => setImageLoading(false)}
                            onError={(e) => {
                                setImageLoading(false);
                                e.currentTarget.onerror = null;
                                e.currentTarget.src = `${baseUrl}${PLACEHOLDER_IMAGE_URL}`;
                            }}
                            style={{ display: imageLoading ? 'none' : 'block' }}
                        />
                    </LazyLoad>
                </Box>
                <Box component={'div'} sx={style.productInfo}>
                    {/* Product name */}
                    {product?.name && (
                        <Typography sx={style.productName}>
                            {product.name}
                        </Typography>
                    )}

                    {/* Product collection */}
                    {productCategory && (
                        <>
                            <Typography sx={style.productSubtitle}>
                                {t('materialPicker.collection')}:&nbsp;
                            </Typography>
                            <Typography sx={style.productSubtitleValue}>
                                {productCategory}
                            </Typography>
                        </>
                    )}

                    {/* Product format */}
                    {productFormat?.featureValue && (
                        <>
                            <Typography sx={style.productSubtitle}>
                                {productFormat?.featureName}:&nbsp;
                            </Typography>
                            <Typography sx={style.productSubtitleValue}>
                                {productFormat?.featureValue}
                            </Typography>
                            <br />
                        </>
                    )}

                    {/* Product thickness */}
                    {plateThickness?.featureValue && (
                        <>
                            <Typography sx={style.productSubtitle}>
                                {plateThickness?.featureName}:&nbsp;
                            </Typography>
                            <Typography sx={style.productSubtitleValue}>
                                {plateThickness?.featureValue}&nbsp;
                            </Typography>
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );
}

// Memoize the component with custom comparison to improve performance
export default React.memo(
    (props: ProductImageProps) => {
        return (
            <ProductImage
                product={props.product}
                onProductClick={props.onProductClick}
            />
        );
    },
    (prevProps, nextProps) => {
        return prevProps.product.id === nextProps.product.id;
    }
);

const style = {
    productItemContainer: {
        display: 'inline-flex',
        justifyContent: 'center',

        border: '0px solid black',
        boxSizing: 'border-box',
        padding: '0rem',
        margin: '0.1rem 0.2rem 0.1rem 0.2rem',
        height: 'auto',
        width: 'auto',
        cursor: 'pointer',
        transition: 'transform 0.1s ease-in-out',

        '&:hover': {
            transform: 'scale(1.0)',
            boxShadow: '0 4px 16px 1px rgba(0, 0, 0, 0.1)',
        },
    },
    productItem: {
        display: 'flex',
        flexDirection: 'column',

        boxSizing: 'border-box',
        border: '0px solid black',
        padding: '0',
        margin: '0',
        minHeight: '26rem',
        width: '9rem',

        overflow: 'hidden',
        wordWrap: 'break-word',
    },
    productImage: {
        flex: 1,
        position: 'relative',

        '& img': {
            width: '100%',
            height: '100%',
            boxSizing: 'border-box',
        },
    },
    loadingContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        boxSizing: 'border-box',
    },
    productInfo: {
        flex: 2,
        padding: '0.2rem',
        boxSizing: 'border-box',
    },
    productName: {
        fontSize: '0.8rem',
        boxSizing: 'border-box',
        marginBottom: '0.4rem',
        borderBottom: `1px solid ${colors.lGrey}`,
        height: '4rem',
    },
    productSubtitle: {
        display: 'inline-block',
        fontSize: '0.8rem',
        fontWeight: 'bold',
        color: colors.fGrey,
        boxSizing: 'border-box',
    },
    productSubtitleValue: {
        display: 'inline-block',
        fontSize: '0.8rem',
        color: colors.fGrey,
        boxSizing: 'border-box',
    },
};
